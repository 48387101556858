// 通用接口前缀
const common_api_prefix = "/commonapi";
// puxitu 接口前缀
const puxitu_api_prefix = "/puxituapi";
// 监测平台接口前缀
const lrm_api_prefix = "/lrmapi";
// 监测平台接口前缀
const lrmv2_api_prefix = "/lrmv2api";
// 监测平台接口前缀
const appcelapi_api_prefix = "/appcelapi";
// 批量导出报告前缀
const exportBatch_api_exportBatch = "/exportapi";

const downloadReport = "/downloadReport";

const lrmv2apilv2Platform = "/lrmv2apilv2Platform";

// const uploadFile = "/uploadFile";

// const url = "http://192.168.40.100:8885";
const url = "http://101.201.126.223:9006";
// 企业用户动态实时访问、教师动态实时访问,学生动态实时访问
// const dynamic = "ws://192.168.40.100:8885";
const dynamic = "ws://101.201.126.223:9006";

const apiurls = {};

apiurls.getAllZykLists = `${lrm_api_prefix}/ResourcePortrait/getAllZykList`;
// 登录页面（验证码，登录）
// 验证码
apiurls.validation_code = `${common_api_prefix}/getVerifyCodeImage`;
// 登录
apiurls.login = `${common_api_prefix}/doLogin`;
// 获取专业
apiurls.major_getAllZykList = `${lrm_api_prefix}/ResourcePortrait/getAllZykList`;
// 切换专业
apiurls.major_switch = `${lrm_api_prefix}/ResourcePortrait/getUpdateZykid`;

// 导出课程质量报告
// apiurls.exportReportByType = exportapi_api_prefix + '/exportReportByType';
// 导出月报报告
apiurls.exportMonthReport = `${lrm_api_prefix}/Lv2ReportFile/exportMonthReport`;
apiurls.exportLv2ReportExportInfo = `${lrm_api_prefix}/exportLv2ReportExportInfo`;

apiurls.report_yaer_getResourceOrUserUpdateVo = `${lrm_api_prefix}/Lv2ReportFile/getResourceOrUserUpdateVo`;

// 获取资源库年报或个性化年度、基本报告列表
apiurls.report_yaer_Monthly_getYearOrPensonYearReportList = `${lrm_api_prefix}/Lv2ReportFile/getYearOrPensonYearReportList`;
// 专业概览(基本信息，国家教学标准，专业开设学院)

// 专业大数据季报访问较多的教师
apiurls.getQuarterTeacherTotalVisitRank = `${lrm_api_prefix}/getQuarterTeacherTotalVisitRank`;
// 专业大数据季报访问较多的学生
apiurls.getQuarterStudentTotalVisitRank = `${lrm_api_prefix}/getQuarterStudentTotalVisitRank`;
// 资源库年报2.2
apiurls.getResourceAddType = `${lrm_api_prefix}/Lv2ReportFile/getResourceAddType`;
// 季报
apiurls.getQuarterCourseAdd = `${lrm_api_prefix}/getQuarterCourseAdd`;
//季报
apiurls.getQuarterResourceAdd = `${lrm_api_prefix}/getQuarterResourceAdd`;
// 年报-年份数据新增对比分析
apiurls.getYearDataAddAnalysis = `${lrm_api_prefix}/getYearDataAddAnalysis`;
// 资源库专业大类建设与应用分析报告表
apiurls.getAllProfessionAnalysisTable = `${appcelapi_api_prefix}/getAllProfessionAnalysisTable`;
// 专业大类数据分析表
apiurls.getProfessionBigClassAnalysis = `${appcelapi_api_prefix}/getProfessionBigClassAnalysis`;
// 资源库季报用户访问时间段分布
apiurls.getLrmv2QuarterBehaviorUserCourseLog = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2QuarterBehaviorUserCourseLog`;
// 资源库季报用户访问时间段分布
apiurls.getLrmv2YearBehaviorUserCourseLog = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2YearBehaviorUserCourseLog`;

// 基本信息
// 获取专业基本信息
apiurls.essential_getProfessionInfo = `${puxitu_api_prefix}/getProfessionInfo`;
apiurls.getProfessionalInfoForRedis = `${puxitu_api_prefix}/getProfessionalInfoForRedis`;
// 专业概览-基本信息
apiurls.essential_getProfessionalReceptionMajor = `${puxitu_api_prefix}/getProfessionalReceptionMajor`;
// 获取岗位词云图
apiurls.essential_getJobWordClouds = `${puxitu_api_prefix}/getJobWordClouds`;
// 获取专业所对应的行业
apiurls.essential_specialty = `${puxitu_api_prefix}/getProfessionIndustryInfos`;
// 获取可从事岗位
apiurls.essential_getProfessionAvailableJobs = `${puxitu_api_prefix}/getProfessionAvailableJobs`;
// 获取专业资源库
apiurls.essential_getZykMonthRank = `${puxitu_api_prefix}/getZykMonthRank`;
// 获取专业证书
apiurls.essential_getCertificateInfo = `${puxitu_api_prefix}/getCertificateInfo`;
// 下载专业证书pdf
apiurls.essential_previewCertificate = `${url}/file/previewCertificate`;
// 整体月报 2.1.4.2 -国家级用户建设及应用情况
apiurls.getCountryUserBuildApplyCase = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getCountryUserBuildApplyCase`;
// 整体月报 2.1.4.3 -国家级课程建设及应用情况
apiurls.getCountryCourseBuildApplyCase = `${lrmv2_api_prefix}/countryResourceMonitoringCloudMap/getCountryCourseBuildApplyCase`;
// 整体月报 -国家级资源建设及应用情况
apiurls.getCountryResourceBuildApplyCase = `${appcelapi_api_prefix}/getCountryResourceBuildApplyCase`;

apiurls.getPlatFormNumByType = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getPlatFormNumByType`;

// 国家教学标准
apiurls.standards_getProfessionStandard = `${puxitu_api_prefix}/getProfessionStandard`;
// 下载
apiurls.downloadTeachStandard = `${url}/file/downloadTeachStandard`;

// 专业开设院校
apiurls.institutions_getProfessionSetUpSchools = `${puxitu_api_prefix}/getProfessionSetUpSchools`;
// 院校开设课程
apiurls.institutions_getSchoolSetUpCourses = `${puxitu_api_prefix}/getSchoolSetUpCourses`;

// 专业教学数据分析(资源库画像,资源库建设进度跟踪,资源库分析报告)
// 资源库画像(整体画像,学生画像,教师画像,课程画像)
// 整体画像
// 用户类型分布
apiurls.overallPortrait_getUserRegisterCount = `${lrm_api_prefix}/ResourcePortrait/getUserRegisterCount`;
// 用户历年新增情况
apiurls.overallPortrait_getUserAddCountEveryYear = `${lrm_api_prefix}/ResourcePortrait/getUserAddCountEveryYear`;
// 师生实名注册情况
apiurls.overallPortrait_getIsrealHasActiveSituation = `${lrm_api_prefix}/ResourcePortrait/getIsrealHasActiveSituation`;
// 主持院校实名教师情况
apiurls.overallPortrait_getRealNameTeachersInHostInstitution = `${lrm_api_prefix}/ResourcePortrait/getRealNameTeachersInHostInstitution`;
// 主持院校实名学生情况
apiurls.overallPortrait_getRealNameStudentInHostInstitution = `${lrm_api_prefix}/ResourcePortrait/getRealNameStudentInHostInstitution`;
// 参建院校实名学生情况
apiurls.overallPortrait_getRealNameStudentsInParticipatingInstitutions = `${lrm_api_prefix}/ResourcePortrait/getRealNameStudentsInParticipatingInstitutions`;
// 无活动教师占比
apiurls.overallPortrait_getProportionOfInactiveTeachers = `${lrm_api_prefix}/ResourcePortrait/getProportionOfInactiveTeachers`;
// 无活动学生占比
apiurls.overallPortrait_getProportionOfInactiveStudents = `${lrm_api_prefix}/ResourcePortrait/getProportionOfInactiveStudents`;
// 参建单位类型分布
apiurls.overallPortrait_getCompanyAndAssociationSituation = `${lrm_api_prefix}/ResourcePortrait/getCompanyAndAssociationSituation`;
// 用户活动趋势图
apiurls.overallPortrait_getUserActivityTrends = `${lrm_api_prefix}/ResourcePortrait/getUserActivityTrends`;
// (31资源类型分布,34资源被课程引用情况)
apiurls.overallPortrait_getResourceKindsStatisticsByTypeForPieEchart = `${lrm_api_prefix}/ResourcePortrait/getResourceKindsStatisticsByTypeForPieEchart`;
// 用户访问行为分布
apiurls.overallPortrait_getUserActionDistributionSituation = `${lrm_api_prefix}/ResourcePortrait/getUserActionDistributionSituation`;
// 文本资源数量占比
apiurls.overallPortrait_getProportionOfTextResources = `${lrm_api_prefix}/ResourcePortrait/getProportionOfTextResources`;
// 资源被引用占比
apiurls.overallPortrait_getReferenceResourceSituation = `${lrm_api_prefix}/ResourcePortrait/getReferenceResourceSituation`;
// 无访问资源占比
apiurls.overallPortrait_getResourceSumByType = `${lrm_api_prefix}/ResourcePortrait/getResourceSumByType`;
// 原创资源占比
apiurls.overallPortrait_getOriginalResource = `${lrm_api_prefix}/ResourcePortrait/getOriginalResource`;
// 本月访问量较多的教师/学生
apiurls.getMonthVisitMoreUserByType = `${lrm_api_prefix}/Lv2ReportFile/getMonthVisitMoreUserByType`;
// 年报_本年度资源库新增及应用情况_主持单位-参建单位本年度新增教师学生用户数_
apiurls.getZykUnitUserYearAdd = `${lrm_api_prefix}/Lv2ReportFile/getZykUnitUserYearAdd`;
// (28-资源历年新增分布图，29-资源历年存储容量，30-资源访问情况，32-不同类型资源容量，33-无访问记录资源数)
apiurls.overallPortrait_getResourceCountByType = `${lrm_api_prefix}/ResourcePortrait/getResourceCountByType`;
// 资源访问行为分布
apiurls.overallPortrait_behaviors = `${lrm_api_prefix}/ResourcePortrait/getUserActionApplicationByYearEchart`;
// 题库题目使用率
apiurls.overallPortrait_getUtilizationRateOfQuestionsInQuestionBank = `${lrm_api_prefix}/ResourcePortrait/getUtilizationRateOfQuestionsInQuestionBank`;
// 课程类型
apiurls.overallPortrait_getCourseCount = `${lrm_api_prefix}/ResourcePortrait/getCourseCount`;
// 课程与资源数量情况
apiurls.overallPortrait_getNumberOfCoursesAndResources = `${lrm_api_prefix}/ResourcePortrait/getNumberOfCoursesAndResources`;
// 课程性质分布
apiurls.overallPortrait_studentsReal = `${lrm_api_prefix}/ResourcePortrait/getStudentIsrealHasActiveSituation`;
// 课程访问时间段分布
apiurls.overallPortrait_getCourseActivityTrends = `${lrm_api_prefix}/ResourcePortrait/getCourseActivityTrends`;
// 课程使用率
apiurls.overallPortrait_getCourseUtilization = `${lrm_api_prefix}/ResourcePortrait/getCourseUtilization`;
// 课程访问行为分布
apiurls.overallPortrait_getCourseKindsStatisticsByTypeAndCourseType = `${lrm_api_prefix}/ResourcePortrait/getCourseKindsStatisticsByTypeAndCourseType`;
// 资源内容年更新比
apiurls.overallPortrait_getAnnualUpdateRatioOfResourceContent = `${lrm_api_prefix}/ResourcePortrait/getAnnualUpdateRatioOfResourceContent`;
// 资源库访问趋势图
apiurls.overallPortrait_getResourceLibraryAccessTrendChart = `${lrm_api_prefix}/ResourcePortrait/getResourceLibraryAccessTrendChart`;
// 表格
apiurls.overallPortrait_getResourceVisitCountRankByType = `${lrm_api_prefix}/ResourcePortrait/getResourceVisitCountRankByType`;

// 学生画像
// 性别分布
apiurls.studentPortrait_getStudentSexEchart = `${lrm_api_prefix}/studentPortrait/getStudentSexEchart`;
// 生源类型分布
apiurls.studentPortrait_getStudentTypeEchart = `${lrm_api_prefix}/studentPortrait/getStudentTypeEchart`;
// 学生所在地域分布
apiurls.studentPortrait_getStudentProvinceUsersEchart = `${lrm_api_prefix}/studentPortrait/getStudentProvinceUsersEchart`;
// 专业分布
apiurls.studentPortrait_getStudentProfessionalEchart = `${lrm_api_prefix}/studentPortrait/getStudentProfessionalEchart`;
// 参建院校实名学生情况(整体画像同一个)
// 无活动学生占比(整体画像同一个)
// 学生用户历年新增数
apiurls.studentPortrait_getUserByYearAddCountEchart = `${lrm_api_prefix}/studentPortrait/getUserByYearAddCountEchart`;
// 学生用户近一年注册数
apiurls.studentPortrait_getStudentByMonthRegisterCountEchart = `${lrm_api_prefix}/studentPortrait/getStudentByMonthRegisterCountEchart`;
// 学生访问时间段分布
apiurls.studentPortrait_getStudentActionTimeDistributionEchart = `${lrm_api_prefix}/studentPortrait/getStudentActionTimeDistributionEchart`;
// 学生近一年访问趋势图
apiurls.studentPortrait_getStudentActionApplicationByYearEchart = `${lrm_api_prefix}/studentPortrait/getStudentActionApplicationByYearEchart`;
// 参建单位学生用户数排名 （前十名）
apiurls.studentPortrait_getConstructionUnitStudentCountEchart = `${lrm_api_prefix}/studentPortrait/getConstructionUnitStudentCountEchart`;
// 访问表格
apiurls.studentPortrait_getStudentVisitCountRankByType = `${lrm_api_prefix}/studentPortrait/getStudentVisitCountRankByType`;

// 教师画像
// 性别分布
apiurls.teacherPortrait_getTeacherSexEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherSexEchart`;
// 年龄分布
apiurls.teacherPortrait_getTeacherAgeEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherAgeEchart`;
// 教师所在地域分布图
apiurls.teacherPortrait_getTeacherProvinceUsersEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherProvinceUsersEchart`;
// 教师用户历年新增数
apiurls.teacherPortrait_getUserByYearAddCountEchart = `${lrm_api_prefix}/teacherPortrait/getUserByYearAddCountEchart`;
// 教师用户近一年注册数
apiurls.teacherPortrait_getTeacherByMonthRegisterCountEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherByMonthRegisterCountEchart`;
// 主持院校实名教师情况(整体画像同一个)
// 教师访问时间段分布
apiurls.teacherPortrait_getTeacherActionTimeDistributionEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherActionTimeDistributionEchart`;
// 无活动教师占比(整体画像有)
// 教师近一年行为趋势图
apiurls.teacherPortrait_getTeacherActionApplicationByYearEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherActionApplicationByYearEchart`;
// 参建单位教师数排名（前10名）
apiurls.teacherPortrait_getTeacherCountByConstructionUnitEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherCountByConstructionUnitEchart`;
// 访问表格
apiurls.teacherPortrait_getTeacherVisitCountRankByType = `${lrm_api_prefix}/teacherPortrait/getTeacherVisitCountRankByType`;
// 受教育程度
apiurls.getTeacherEduationEchart = `${lrm_api_prefix}/teacherPortrait/getTeacherEduationEchart`;
// 教师发布作业
apiurls.getCourseWorkCase = `${lrm_api_prefix}/teacherPortrait/getCourseWorkCase`;
// 论坛发布情况
apiurls.getForumReleaseCase = `${lrm_api_prefix}/teacherPortrait/getForumReleaseCase`;
// 教师发布课程
apiurls.getTeacherReleaseCourse = `${lrm_api_prefix}/teacherPortrait/getTeacherReleaseCourse`;
// 教师发布资源
apiurls.getTeacherReleaseResource = `${lrm_api_prefix}/teacherPortrait/getTeacherReleaseResource`;

// 课程画像
// 课程数量(整体画像的课程类型)
// apiurls.curriculumPortrait_getCourseTypeCountEchart = lrm_api_prefix + '/coursePortrait/getCourseTypeCountEchart';
// // 课程性质分布
// apiurls.curriculumPortrait_variousResources = lrm_api_prefix + '/coursePortrait/getCourseResourceNumByTypeEchart';
// 课程使用率(整体画像有)
// 课程上传资源数排名（前10名）
apiurls.curriculumPortrait_getCourseUploadResourceRank = `${lrm_api_prefix}/coursePortrait/getCourseUploadResourceRank`;
// 课程访问时间段分布
apiurls.curriculumPortrait_getCourseActionTimeDistributionEchart = `${lrm_api_prefix}/coursePortrait/getCourseActionTimeDistributionEchart`;
// 课程引用资源数排名（前10名）
apiurls.curriculumPortrait_getCourseRefResourceRank = `${lrm_api_prefix}/coursePortrait/getCourseRefResourceRank`;
// 课程近一年访问趋势图（标准课程,个性化课程）
apiurls.curriculumPortrait_getCourseMonthlyActionCountEchart = `${lrm_api_prefix}/coursePortrait/getCourseMonthlyActionCountEchart`;
// 开课期数少于2期的课程(在整体画像访问表格接口里)

// 标准化课程学习人数排名(前10名)
apiurls.curriculumPortrait_getCourseStandardStudyRank = `${lrm_api_prefix}/coursePortrait/getCourseStandardStudyRank`;
// 标准化课程访问量排名(前10名)
apiurls.curriculumPortrait_getCourseStandardVisitRank = `${lrm_api_prefix}/coursePortrait/getCourseStandardVisitRank`;
// 个性化课程访问量排名(前10名)
apiurls.curriculumPortrait_getCoursePersonalVisitRank = `${lrm_api_prefix}/coursePortrait/getCoursePersonalVisitRank`;
// 课程开课期数排名(前5名)
apiurls.curriculumPortrait_getCoursePeriodsRank = `${lrm_api_prefix}/coursePortrait/getCoursePeriodsRank`;
apiurls.getCourseSelection = `${lrm_api_prefix}/coursePortrait/getCourseSelection`;

// 资源库分析报告

// 年度分析报告,年度个性化报告  (年度基本信息)
apiurls.report_yaer_getZykInformationVo = `${lrm_api_prefix}/Lv2ReportFile/getZykInformationVo`;
// 当前资源库资源、课程情况
apiurls.report_year_getStatResourceDataEntity = `${lrm_api_prefix}/Lv2ReportFile/getStatResourceDataEntity`;
// 标准化课程数
apiurls.report_year_getYearStatStandardcourseaccessList = `${lrm_api_prefix}/Lv2ReportFile/getYearStatStandardcourseaccessList`;
apiurls.report_yaer_getZykRankYear = `${lrm_api_prefix}/Lv2ReportFile/getZykRankYear`;
// 用户新增情况（教师+学生）
apiurls.report_year_getYearhZykAdd = `${lrm_api_prefix}/Lv2ReportFile/getYearhZykAdd`;
// 总访问量较多的教师（前十）
apiurls.report_year_getStatLittletenEntityList = `${lrm_api_prefix}/Lv2ReportFile/getStatLittletenEntityList`;
// 年报_年度访问量排名前十实名教师-学生
apiurls.report_year_getStatLittletenEntityListByYearReport = `${lrm_api_prefix}/Lv2ReportFile/getStatLittletenEntityListByYearReport`;
// 年报_年度访问量排名前十实名教师-资源、课程
apiurls.getLrmv2YearVisitCourseOrResourceRank = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2YearVisitCourseOrResourceRank`;
// 年报_年度访问量排名前十实名教师-资源、课程
apiurls.getLogModuleCase = `${lrm_api_prefix}/getLogModuleCase`;

// 获取资源库月度和季度报告列表
apiurls.report_year_getMonthOrQuarterReportList = `${lrm_api_prefix}/Lv2ReportFile/getMonthOrQuarterReportList`;

// 未实名用户情况-数据截止时间
apiurls.report_year_getUnitNoRealUserCreateTimeByType = `${lrm_api_prefix}/Lv2ReportFile/getUnitNoRealUserCreateTimeByType`;
// 无活动数据情况-数据截止时间
apiurls.report_year_getNoAccessUserCreateTimeByType = `${lrm_api_prefix}/Lv2ReportFile/getNoAccessUserCreateTimeByType`;

// 资源库分析报告

apiurls.report_yaer_Monthly_downloadReportFile = `${lrmv2_api_prefix}/Lv2ReportFile/downloadReportFile`;
// 年度分析报告表格接口
apiurls.report_year_getBasicReportUserVo = `${lrm_api_prefix}/Lv2ReportFile/getBasicReportUserVo`;
apiurls.report_year_getBasicReportResourceVo = `${lrm_api_prefix}/Lv2ReportFile/getBasicReportResourceVo`;

apiurls.report_year_getCourseCountVo = `${lrm_api_prefix}/Lv2ReportFile/getCourseCountVo`;
apiurls.report_year_getResourceApplicationVo = `${lrm_api_prefix}/Lv2ReportFile/getResourceApplicationVo`;

apiurls.report_yaer_getDeleteNum = `${lrm_api_prefix}/Lv2ReportFile/getDeleteNum`;
apiurls.getBriefUserCount = `${lrm_api_prefix}/Lv2ReportFile/getBriefUserCount`;

// 课程质量报告
apiurls.report_qualityReport_getTableList = `${lrm_api_prefix}/courseQualityReport/getCourseQualityReportList`;

// 查看课程质量报告
apiurls.report_qualityReport_getCourseQualityReportDetail = `${lrm_api_prefix}/courseQualityReport/getCourseQualityReportDetail`;
apiurls.report_qualityReport_getCourseQualityCourseNper = `${lrm_api_prefix}/courseQualityReport/getCourseQualityCourseNper`;
apiurls.report_qualityReport_getResourceTypeLogCountByCourseName = `${lrm_api_prefix}/courseQualityReport/getResourceTypeLogCountByCourseName`;
apiurls.report_qualityReport_getReportCourseLearningEntity = `${lrm_api_prefix}/courseQualityReport/getReportCourseLearningEntity`;
apiurls.report_qualityReport_getCourseWork = `${lrm_api_prefix}/courseQualityReport/getCourseWork`;
apiurls.report_qualityReport_getCreditsRank = `${lrm_api_prefix}/courseQualityReport/getCreditsRank`;
apiurls.report_qualityReport_getPostingSituation = `${lrm_api_prefix}/courseQualityReport/getPostingSituation`;
apiurls.report_qualityReport_getDayOrMonthLive = `${lrm_api_prefix}/courseQualityReport/getDayOrMonthLive`;
apiurls.report_qualityReport_getSchoolNperSituation = `${lrm_api_prefix}/courseQualityReport/getSchoolNperSituation`;
apiurls.report_qualityReport_getCourseQuoteResource = `${lrm_api_prefix}/courseQualityReport/getCourseQuoteResource`;
apiurls.report_qualityReport_getCourseMonthQuestionAddNum = `${lrm_api_prefix}/courseQualityReport/getCourseMonthQuestionAddNum`;
apiurls.report_qualityReport_getCourseInteraction = `${lrm_api_prefix}/courseQualityReport/getCourseInteraction`;
apiurls.report_qualityReport_getReportCourseLearningEntity = `${lrm_api_prefix}/courseQualityReport/getReportCourseLearningEntity`;

// 月度分析报告
apiurls.report_monthlyReport_getLv2ReportFileEntityList = `${lrm_api_prefix}/Lv2ReportFile/getLv2ReportFileEntityList`;

// 在线月度报告接口
// 当前资源库用户情况
apiurls.report_monthly_resourceLibrary = `${lrm_api_prefix}/Lv2ReportFile/getUserCount`;
apiurls.report_monthly_standardization = `${lrm_api_prefix}/Lv2ReportFile/getStatStandardcourseaccessList`;
apiurls.report_monthly_usersResourceLibraryTop10 = `${lrm_api_prefix}/Lv2ReportFile/getStatJoinUnitUserCountEntityList`;
apiurls.report_monthly_behaviorTypes = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2LogModule`;
apiurls.report_monthly_resourcePool = `${lrm_api_prefix}/Lv2ReportFile/getStatUserActive`;
apiurls.report_monthly_resourceBase = `${lrm_api_prefix}/Lv2ReportFile/getZykRank`;
apiurls.report_monthly_usersMonth = `${lrm_api_prefix}/Lv2ReportFile/getMonthZykAdd`;
apiurls.report_monthly_getUserCountUnit = `${lrm_api_prefix}/Lv2ReportFile/getUserCountUnit`;
apiurls.report_monthly_getMajorUser = `${lrm_api_prefix}/Lv2ReportFile/getMajorUser`;
apiurls.report_monthly_getLrmv2MonthUserCourseResource = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2MonthUserCourseResource`;
apiurls.report_monthly_getLrmv2MonthBehaviorUserCourseLog = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2MonthBehaviorUserCourseLog`;
apiurls.report_monthly_getZykStatus = `${lrm_api_prefix}/Lv2ReportFile/getZykStatus`;

// 在线季度报告接口
apiurls.quarterly_monthly_getQuarterZykAdd = `${lrm_api_prefix}/Lv2ReportFile/getQuarterZykAdd`;
apiurls.quarterly_monthly_getQuarterMajorUser = `${lrm_api_prefix}/Lv2ReportFile/getQuarterMajorUser`;
apiurls.quarterly_monthly_getLrmv2QuarterUserCourseResource = `${lrm_api_prefix}/Lv2ReportFile/getLrmv2QuarterUserCourseResource`;

// 导出学生教师应用报告
apiurls.exportTeaStuReport = `${lrm_api_prefix}/StudentTeacherReport/exportTeaStuReport`;
// 教师应用报告
apiurls.applicationReport_getList = `${lrm_api_prefix}/StudentTeacherReport/getStudentTeacherReportList`;
apiurls.applicationReport_getZykUserInfoVoByRole = `${lrm_api_prefix}/StudentTeacherReport/getZykUserInfoVoByRole`;
// 查看教师应用报告
apiurls.applicationReport_getStudentTeacherReportDetail = `${lrm_api_prefix}/StudentTeacherReport/getStudentTeacherReportDetail`;
// 用户近一年访问时间段分布情况
apiurls.applicationReport_getStudentTeacherReportTimepart = `${lrm_api_prefix}/StudentTeacherReport/getStudentTeacherReportTimepart`;
// 该教师访问时间段分布
apiurls.applicationReport_getStudentTeacherReportTimepart = `${lrm_api_prefix}/StudentTeacherReport/getStudentTeacherReportTimepart`;
// 该教师所创建的课程
apiurls.applicationReport_getUserReportCreateCourse = `${lrm_api_prefix}/StudentTeacherReport/getUserReportCreateCourse`;
// 该学生所学习的课程及学习时长
apiurls.applicationReport_getStudentReportCourseReg = `${lrm_api_prefix}/StudentTeacherReport/getStudentReportCourseReg`;
// 该教师所上传资源
apiurls.applicationReport_getTeacherReportUploadResource = `${lrm_api_prefix}/StudentTeacherReport/getTeacherReportUploadResource`;

// 无活动数据
apiurls.activeData_getZykEntityDetail = `${lrmv2_api_prefix}/ExportData/getZykEntityDetail`;
apiurls.activeData_getNoAccessResource = `${lrmv2_api_prefix}/ExportData/getNoAccessResource`;
apiurls.activeData_getNoAccessUserByType = `${lrmv2_api_prefix}/ExportData/getNoAccessUserByType`;
// 建设院校未实名用户
apiurls.activeData_getUnitNoRealUserByType = `${lrmv2_api_prefix}/ExportData/getUnitNoRealUserByType`;

// 其他维度分析报告
apiurls.analysisReport_getList = `${lrm_api_prefix}/DataAnalysis/getResourceOverallApplicationList`;
apiurls.applicationReport_resourceBase = `${lrm_api_prefix}/DataAnalysis/getStandardCourseApplyRankEntityListByCreateTimeStr`;
apiurls.applicationReport_universities = `${lrm_api_prefix}/DataAnalysis/getZykWeekSchoolResultEntityList`;
apiurls.applicationReport_getStandardCourseApplyRankExcel = `${lrmv2_api_prefix}/ExportData/getStandardCourseApplyRankExcel`;
apiurls.applicationReport_getZykWeekSchoolResultExcelDetail = `${lrmv2_api_prefix}/ExportData/getZykWeekSchoolResultExcelDetail`;

// 不同类型用户行为分布
apiurls.applicationReport_getBriefUserByTypeDistribution = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserByTypeDistribution`;
// 运行简报
// 用户总览
apiurls.ReportFile_getBriefUserCount = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserCount`;
// 用户注册情况分布  // 素材资源
apiurls.ReportFile_getBriefUserNew = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserNew`;

// 简报-当前资源库参建单位注册用户数排名
apiurls.ReportFile_getBriefSchoolUserHot = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefSchoolUserHot`;
apiurls.ReportFile_getBriefUserByTypeDayDistribution = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserByTypeDayDistribution`;
apiurls.Lv2ReportFile_getBriefUserActionTotal = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserActionTotal`;
apiurls.Lv2ReportFile_getBriefHotResource = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefHotResource`;
apiurls.Lv2ReportFile_getBriefUserByDayDistribution = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserByDayDistribution`;
apiurls.Lv2ReportFile_getBriefProvinceDistribution = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefProvinceDistribution`;
apiurls.Lv2ReportFile_getBriefUserByYear = `${lrmv2_api_prefix}/Lv2ReportFile/getBriefUserByYear?type=3&endTime=2023-04`;
// 专业就业市场分析
// 获取数据源下面的内容
apiurls.marketAnalysis_dataText = `${puxitu_api_prefix}/jobMarketAnalysis/getGlobeAndCurrentAreaDataCount`;
// 获取数据源
apiurls.marketAnalysis_source = `${puxitu_api_prefix}/jobMarketAnalysis/getStatDataSource`;
// 本专业岗位需求排名(15名)
apiurls.marketAnalysis_ranking = `${puxitu_api_prefix}/jobMarketAnalysis/getEchartsDataByType`;
// 本专业岗位薪酬排名
apiurls.marketAnalysis_posts = `${puxitu_api_prefix}/jobMarketAnalysis/getSalaryAvgRank`;
// 本专业面向职业需求排名
apiurls.marketAnalysis_getProfessionHotJob = `${puxitu_api_prefix}/getProfessionHotJob`;
// 本专业岗位薪酬行业分布情况
apiurls.marketAnalysis_professional = `${puxitu_api_prefix}/getEchartsDataByType`;
// 专业就业市场分析-本专业岗位薪酬行业分布情况
apiurls.marketAnalysis_getProfessionEnterpriseNum = `${puxitu_api_prefix}/getProfessionEnterpriseNum`;
// 专业就业市场分析-本专业招聘单位分布情况(新指标名称：本专业招聘单位类型分布)
apiurls.marketAnalysis_getProfessionEnterpriseTypeNum = `${puxitu_api_prefix}/getProfessionEnterpriseTypeNum`;
// 岗位词云图(按时间版本)
apiurls.marketAnalysis_getJobWordCloudsByVersion = `${puxitu_api_prefix}/jobMarketAnalysis/getJobWordCloudsByVersion`;
// 本专业招聘单位分布情况
apiurls.marketAnalysis_discipline = `${puxitu_api_prefix}/jobMarketAnalysis/getEchartsDataByType`;
// 本专业招聘单位分布情况
apiurls.marketAnalysis_getPostNum = `${puxitu_api_prefix}/getPostNum`;
// 专业就业市场分析-本专业岗位需求地域分布
apiurls.marketAnalysis_getEnterpriseRegion = `${puxitu_api_prefix}/getEnterpriseRegion`;
// 月报-本专业面向职业发展方向分布
apiurls.marketAnalysis_getDevelopmentDirection = `${puxitu_api_prefix}/getDevelopmentDirection`;
// 全国区域本专业等指标
apiurls.marketAnalysis_getProfessionalInstructionsStatisticsByMajorId = `${puxitu_api_prefix}/getProfessionalInstructionsStatisticsByMajorId`;
// 全国区域本专业人才薪资分布情况
apiurls.marketAnalysis_country = `${puxitu_api_prefix}/jobMarketAnalysis/getGlobeStatTypePersonNum`;
// 全国区域本专业人才学历要求情况
apiurls.marketAnalysis_regions = `${puxitu_api_prefix}/jobMarketAnalysis/getGlobeStatTypePersonNum`;
// 全国区域本专业人才经验要求情况
apiurls.marketAnalysis_requirements = `${puxitu_api_prefix}/jobMarketAnalysis/getGlobeStatTypePersonNum`;
// 院校所在区域(北京)本专业人才薪资分布情况(单位：k/月)
apiurls.marketAnalysis_university = `${puxitu_api_prefix}/jobMarketAnalysis/getAreaSalaryPersonCountList`;
// 院校所在区域(XX)本专业人才薪资分布情况(新指标名称：院校所在区域（XX）本专业人才薪资分布）
apiurls.marketAnalysis_getSalaryNum = `${puxitu_api_prefix}/getSalaryNum`;
// 院校所在区域(XX)本专业人才学历要求情况(新指标名称：院校所在区域（XX）本专业人才学历要求分布）
apiurls.marketAnalysis_getEducationNum = `${puxitu_api_prefix}/getEducationNum`;
// 院校所在区域(XX)本专业人才经验要求情况(新指标名称：院校所在区域（XX）本专业人才工作经验要求分布）
apiurls.marketAnalysis_getWorkingYearsNum = `${puxitu_api_prefix}/getWorkingYearsNum`;
apiurls.marketAnalysis_getSalaryNum = `${puxitu_api_prefix}/getSalaryNum`;

// 院校所在区域(北京)本专业人才学历要求情况
apiurls.marketAnalysis_located = `${puxitu_api_prefix}/jobMarketAnalysis/getAreaEducationExperiencePersonNum`;
// 院校所在区域(北京)本专业人才经验要求情况
apiurls.marketAnalysis_talents = `${puxitu_api_prefix}/jobMarketAnalysis/getAreaEducationExperiencePersonNum`;
// 本专业岗位需求行业分布情况
apiurls.marketAnalysis_distribution = `${puxitu_api_prefix}/jobMarketAnalysis/getEchartsDataByType`;
// 本专业岗位薪酬地域分布情况
apiurls.marketAnalysis_salaryposts = `${puxitu_api_prefix}/jobMarketAnalysis/getEchartsDataByType`;
// 全国区域本专业岗位需求分布情况 地图
apiurls.marketAnalysis_postsAcross = `${puxitu_api_prefix}/jobMarketAnalysis/getEchartsDataByType`;
// 全国区域本专业市场需求分布
apiurls.marketAnalysis_getCityPostOrSchoolNum = `${puxitu_api_prefix}/getCityPostOrSchoolNum`;
// 月报-本专业开设院校办学性质分布
apiurls.marketAnalysis_getSchoolNatureChart = `${puxitu_api_prefix}/getSchoolNatureChart`;
// 月报-本专业开设院校地域分布
apiurls.marketAnalysis_getSchoolRegion = `${puxitu_api_prefix}/getSchoolRegion`;

// 职业能力分析(职业画像、专业岗位查询)
// 职业画像
// 获取数据源版本、就业方向
apiurls.puxitu_job_directory_and_version = `${puxitu_api_prefix}/professionModel/getJobDirectionAndVersion`;
// 职业能力分析-获取省份下拉
apiurls.puxitu_getProvinceGroup = `${puxitu_api_prefix}/getProvinceGroup`;
// 职业能力分析-职业列表下拉
apiurls.puxitu_getProfessionalTop10 = `${puxitu_api_prefix}/getProfessionalTop10`;
// 职业能力分析-薪资变动趋势
apiurls.puxitu_getJobSalaryTrent = `${puxitu_api_prefix}/getJobSalaryTrent`;
// 职业能力分析-招聘需求趋势
apiurls.puxitu_getJobNeedTrent = `${puxitu_api_prefix}/getJobNeedTrent`;
// 职业能力分析-薪资变动排名
apiurls.puxitu_getAreaSalaryRank = `${puxitu_api_prefix}/getAreaSalaryRank`;
// 职业能力分析-专业岗位查询
apiurls.puxitu_queryMajorSkillMaps = `${puxitu_api_prefix}/queryMajorSkillMaps`;

// 获取岗位词云图
apiurls.professionalPortrait_getPositionWordCloud = `${puxitu_api_prefix}/getPositionWordCloud`;
// 职业技能词云图
apiurls.professionalPortrait_getSkillEntityInfos = `${puxitu_api_prefix}/getSkillEntityInfos`;
// 学历要求分布,薪资分布
apiurls.professionalPortrait_requirements = `${puxitu_api_prefix}/getProfessionBook`;
// 获取职业仓对比年度
apiurls.professionalPortrait_getCompareYears = `${puxitu_api_prefix}/getCompareYears`;
// 根据年度获取职业仓对比季度
apiurls.professionalPortrait_getCompareSeasonByYear = `${puxitu_api_prefix}/getCompareSeasonByYear`;
// 根据季度获取就业方向
apiurls.professionalPortrait_getCompareDirectBySeason = `${puxitu_api_prefix}/getCompareDirectBySeason`;
// 对比结果
apiurls.professionalPortrait_getCompareData = `${puxitu_api_prefix}/getCompareData`;

// 专业岗位查询
// 能胜任岗位查询
apiurls.positionQuery_getChartByMajorEducationExperience = `${puxitu_api_prefix}/neo4j/getChartByMajorEducationExperience`;
// 岗位能力查询
apiurls.positionQuery_getChartByPositionName = `${puxitu_api_prefix}/neo4j/getChartByPositionName`;
// 能适应、感兴趣岗位查询
apiurls.positionQuery_getChartBySkill = `${puxitu_api_prefix}/neo4j/getChartBySkill`;

// 管理中心(问卷管理，账户管理，菜单管理)
// 账户管理
// 校园端-账户列表
apiurls.account_getList = `${lrmv2_api_prefix}/getUserList`;
// 国家端-账户列表
apiurls.account_getUserListByCountry = `${lrmv2_api_prefix}/getUserListByCountry`;

// 获取可分配的专业
apiurls.account_specialty = `${lrmv2_api_prefix}/getUserProfessionids`;
// 绑定用户专业
apiurls.account_bindUserProfessions = `${lrmv2_api_prefix}/bindUserProfessions`;
// 用户删除
apiurls.account_deleteUserInfo = `${lrmv2_api_prefix}/deleteUserInfo`;
// 添加用户和编辑用户
apiurls.account_saveOrUpdateUser = `${lrmv2_api_prefix}/saveOrUpdateUser`;
// 用户启用、禁用
apiurls.account_updateUserEnable = `${lrmv2_api_prefix}/updateUserEnable`;

// 获取用户绑定的角色id-所有角色
apiurls.account_getAllRoleAndBindRoleId = `${lrmv2_api_prefix}/getAllRoleAndBindRoleId`;
// 绑定用户角色
apiurls.account_bindUserRole = `${lrmv2_api_prefix}/bindUserRole`;
// 获取所有的平台
apiurls.account_getAllPlatformInfos = `${lrmv2_api_prefix}/getAllPlatformInfos`;
// 绑定平台
apiurls.account_bindUserPlatform = `${lrmv2_api_prefix}/bindUserPlatform`;
// 角色列表（国家 + 校园）
apiurls.account_getUserRoleList = `${lrmv2_api_prefix}/getUserRoleList`;

// 任务模块集合
apiurls.dataStatisticsMonitor_getLrmv2StatisticalTaskModuleInfoList = `${lrmv2_api_prefix}/dataStatisticsMonitor/getLrmv2StatisticalTaskModuleInfoList`;
// 任务类型集合
apiurls.dataStatisticsMonitor_getLrmv2StatisticalTaskInfoList = `${lrmv2_api_prefix}/dataStatisticsMonitor/getLrmv2StatisticalTaskInfoList`;
// 任务数据执行统计状态总进度列表
apiurls.dataStatisticsMonitor_getLrmv2StatisticalTaskModuleInfoVoList = `${lrmv2_api_prefix}/dataStatisticsMonitor/getLrmv2StatisticalTaskModuleInfoVoList`;
// 任务数据执行统计状态查询列表
apiurls.dataStatisticsMonitor_getLrmv2StatisticalTaskZykInfoByTypeOrStatus = `${lrmv2_api_prefix}/dataStatisticsMonitor/getLrmv2StatisticalTaskZykInfoByTypeOrStatus`;

// 学校列表
apiurls.account_getSchoolSelectionDatas = `${lrmv2_api_prefix}/getSchoolSelectionDatas`;

// 菜单管理
// 菜单列表
apiurls.account_getMenuList = `${common_api_prefix}/menu/getMenuList`;
// 父级菜单
apiurls.account_getParentMenuList = `${common_api_prefix}/menu/getParentMenuList`;
// 菜单删除
apiurls.account_deleteMenuInfo = `${common_api_prefix}/menu/deleteMenuInfo`;
// 添加菜单和编辑菜单
apiurls.account_saveOrUpdateMenuInfo = `${common_api_prefix}/menu/saveOrUpdateMenuInfo`;

// 角色管理
// 角色列表
apiurls.account_getRoleList = `${common_api_prefix}/role/getRoleList`;
// 角色删除
apiurls.account_deleteRoleInfo = `${common_api_prefix}/role/deleteRoleInfo`;
// 添加角色和编辑角色
apiurls.account_saveOrUpdateRoleInfo = `${common_api_prefix}/role/saveOrUpdateRoleInfo`;
// 获取角色绑定的菜单id和所有菜单列表
apiurls.account_getRoleBindMenusAndAllMenu = `${common_api_prefix}/role/getRoleBindMenusAndAllMenu`;
// 角色绑定菜单
apiurls.account_bindRoleMenu = `${common_api_prefix}/role/bindRoleMenu`;

// 院校管理
// 院校列表
apiurls.account_getSchoolList = `${lrmv2_api_prefix}/getSchoolInfo`;
// 院校删除
apiurls.account_delSchoolInfo = `${lrmv2_api_prefix}/delSchoolInfo`;
// 院校新增
apiurls.account_addSchoolInfo = `${lrmv2_api_prefix}/addSchoolInfo`;
// 院校修改
apiurls.account_editSchoolInfo = `${lrmv2_api_prefix}/editSchoolInfo`;

// 报告下发
// 报告列表
apiurls.getLv2ReportFile = `${lrm_api_prefix}/getLv2ReportFile`;
// 生成封面
apiurls.getReportBg = `${appcelapi_api_prefix}/getReportBg`;
// 报告删除
apiurls.delLv2ReportFile = `${lrm_api_prefix}/delLv2ReportFile`;
// 报告新增
apiurls.addLv2ReportFile = `${lrm_api_prefix}/addLv2ReportFile`;
// 报告修改
apiurls.edLv2ReportFile = `${lrm_api_prefix}/edLv2ReportFile`;
// 复制
apiurls.insertLv2ReportFile = `${lrm_api_prefix}/insertLv2ReportFile`;

// 指标库
// 指标新增编辑
apiurls.account_saveOrUpdateIndicator = `${lrmv2_api_prefix}/indicator/saveOrUpdateIndicator`;

// 指标配置
// 指标配置页面列表查询
apiurls.account_listIndicatorByPageIdAndIndicatorName = `${lrmv2_api_prefix}/indicator/listIndicatorByPageIdAndIndicatorName`;

// 查询需要添加指标的数据
apiurls.account_showIndicatorsByTagAndNameWithPageId = `${lrmv2_api_prefix}/indicator/showIndicatorsByTagAndNameWithPageId`;

// 资源库建设进度跟踪
// 任务进度规划
apiurls.taskPlanProgress = `${lrmv2_api_prefix}/getTaskProgressPlanData`;
// 任务进度规划保存目标值
apiurls.saveTaskProgressPlanTargetValue = `${lrmv2_api_prefix}/saveTaskProgressPlanTargetValue`;
// 资源库总体建设进度跟踪
apiurls.resourceLibrary = `${lrmv2_api_prefix}/getTaskProgressPlansPopulation`;
// 查询各参建院校-子项目列表
apiurls.getConstructionProgressOfParticipatingInstitutionPlanList = `${lrmv2_api_prefix}/getConstructionProgressOfParticipatingInstitutionPlanList`;
// 各参建院校-各子项目建设进度规划
apiurls.getConstructionProgressOfParticipatingInstitutionPlan = `${lrmv2_api_prefix}/getConstructionProgressOfParticipatingInstitutionPlan`;
// 修改各参建院校-各子项目建设进度规划
apiurls.updateConstructionProgressOfParticipatingInstitutionPlan = `${lrmv2_api_prefix}/updateConstructionProgressOfParticipatingInstitutionPlan`;
// 各参建院校-各子项目建设进度
apiurls.getConstructionProgressOfParticipatingInstitutionEcharts = `${lrmv2_api_prefix}/getConstructionProgressOfParticipatingInstitutionEcharts`;

// 各参建院校建设进度-教师课程建设情况(前10名)
apiurls.getTeacherCourseBuildList = `${lrmv2_api_prefix}/getTeacherCourseBuildList`;
// 各参建院校建设进度-学生选课情况(前10名)
apiurls.getUnitStudentSeletionCourseList = `${lrmv2_api_prefix}/getUnitStudentSeletionCourseList`;
// 各参建院校建设进度-教师、学生专业分布
apiurls.getTeacherStudentProfessionDistribute = `${lrmv2_api_prefix}/getTeacherStudentProfessionDistribute`;
// 各子项目建设进度-选课情况
apiurls.getCourseStudentSeletionCourseList = `${lrmv2_api_prefix}/getCourseStudentSeletionCourseList`;
// 合作企业名单列表
apiurls.cooperativeEnterprise_getCompanyLists = `${lrmv2_api_prefix}/cooperativeEnterprise/getCompanyList`;
// 合作企业名单新增-编辑
apiurls.cooperativeEnterprise_saveOrUpdateCompany = `${lrmv2_api_prefix}/cooperativeEnterprise/saveOrUpdateCompany`;
// 合作企业名单删除
apiurls.cooperativeEnterprise_deleteCompanyInfo = `${lrmv2_api_prefix}/cooperativeEnterprise/deleteCompanyInfo`;
// 合作企业名单批量导入
apiurls.cooperativeEnterprise_uploadFile = `${lrmv2_api_prefix}/cooperativeEnterprise/uploadFile`;

// 报告自动化导出列表
apiurls.getReportListByType = `${lrmv2_api_prefix}/reportAutoMgt/getReportListByType`;
// 执行报告生成
apiurls.execeReportExport = `${lrmv2_api_prefix}/reportAutoMgt/execeReportExport`;
// 导出报告
apiurls.reportAutoMgt_downloadReport = `${lrmv2_api_prefix}/reportAutoMgt/exportReportByType`;
// // 各参建院校建设进度-学生选课情况
// apiurls.findCourseRegCount = lrmv2_api_prefix + '/findCourseRegCount';
// // 各子项目建设进度-不同类型资源数量
// apiurls.getResourceForType = lrmv2_api_prefix + '/getResourceForType';
// // 各子项目建设进度-本月建设与应用情况
// apiurls.getMonthSetUpApplyCase = lrmv2_api_prefix + '/getMonthSetUpApplyCase';

// 监测云图
// 参建院校
// 参建院校最多的专业大类排名
apiurls.monitoringCloud_eachMajor = `${appcelapi_api_prefix}/getMajorInfoVoList`;
// 参建院校最多的专业大类排名
apiurls.monitoringCloud_poolMost = `${appcelapi_api_prefix}/getUnitInfoVoList`;
// 参建院校地域-1-地域分布，2-省份分布
apiurls.monitoringCloud_participating = `${appcelapi_api_prefix}/getPositionOrProvinceVoList`;
// 第一主持院校名单(建设2个及以上)
apiurls.monitoringCloud_getPresideTopList = `${appcelapi_api_prefix}/getPresideTopList`;
// 历年资源库主持单位区域变动情况
apiurls.monitoringCloud_resourcePool = `${appcelapi_api_prefix}/getYearPositionVoList`;

// 教师画像,学生画像,企业用户
// 活跃教师排名榜,访问课程次数排名,总访问排名
apiurls.onitoringTeacherPortrait_getSumUserVisitCountRankByType = `${appcelapi_api_prefix}/userPortrait/getSumUserVisitCountRankByType`;
// 教师所在地域分布
apiurls.onitoringTeacherPortrait_getSumUserProvinceUsersEchart = `${appcelapi_api_prefix}/userPortrait/getSumUserProvinceUsersEchart`;
// 受教育程度
apiurls.onitoringTeacherPortrait_getSumTeacherEduationEchart = `${appcelapi_api_prefix}/userPortrait/getSumTeacherEduationEchart`;
// // 年龄分布
// apiurls.onitoringTeacherPortrait_getSumTeacherAgeEchart = appcelapi_api_prefix + '/userPortrait/getSumTeacherAgeEchart';
// 性别比
apiurls.onitoringTeacherPortrait_getSumUserSex = `${appcelapi_api_prefix}/userPortrait/getSumUserSex`;
// 专业分布
apiurls.onitoringTeacherPortrait_getSumStudentProvinceUsersEchart = `${appcelapi_api_prefix}/userPortrait/getSumStudentProvinceUsersEchart`;
// 类型分布
apiurls.onitoringTeacherPortrait_getSumStudentTypeEchart = `${appcelapi_api_prefix}/userPortrait/getSumStudentTypeEchart`;
// 近一年月注册数图
apiurls.onitoringTeacherPortrait_getSumStudentByMonthRegisterCountEchart = `${appcelapi_api_prefix}/userPortrait/getSumStudentByMonthRegisterCountEchart`;
// 参建院校用户数排名
apiurls.onitoringTeacherPortrait_getSumJoinUnitUserCountRank = `${appcelapi_api_prefix}/userPortrait/getSumJoinUnitUserCountRank`;

// 学生
// 近一年月注册数图
apiurls.onitoringTeacherPortrait_getSumUserByMonthRegisterCountEchart = `${appcelapi_api_prefix}/userPortrait/getSumUserByMonthRegisterCountEchart`;
// 用户行为时间段分布
apiurls.onitoringTeacherPortrait_getSumUserActionTimeDistributionEchart = `${appcelapi_api_prefix}/userPortrait/getSumUserActionTimeDistributionEchart`;
// 资源库企业用户数排名
apiurls.onitoringTeacherPortrait_getInstituteCountList = `${appcelapi_api_prefix}/getInstituteCountList`;
// 企业用户访问实时动态
apiurls.onitoringTeacherPortrait_dynamic = `${dynamic}/webSocket/qiye`;
// 教师实时范文
apiurls.materialResources_material = `${dynamic}/webSocket/teacher`;
// 学生实时范文
apiurls.materialResources_material = `${dynamic}/webSocket/student`;
// 参建企业区域分布排名
apiurls.onitoringTeacherPortrait_getEnterpriseProvinceDistribution = `${appcelapi_api_prefix}/userPortrait/getEnterpriseProvinceDistribution`;
// 参建企业类型分布
apiurls.onitoringTeacherPortrait_getEnterpriseNatureDistribution = `${appcelapi_api_prefix}/userPortrait/getEnterpriseNatureDistribution`;

// 标准化课程
// 课程总上传资源数排名 (39-(课程资源数排名)，40-课程本月上传资源数排名)
apiurls.standardizationCurriculum_getSumCourseResourceRank = `${appcelapi_api_prefix}/coursePortrait/getSumCourseResourceRank`;
// 最热课程排行榜
apiurls.standardizationCurriculum_getHotCourseCount = `${appcelapi_api_prefix}/userPortrait/getHotCourseCount`;
// 课程本月上传资源数排名
apiurls.standardizationCurriculum_poolMost = `${appcelapi_api_prefix}/getUnitInfoVoList`;
// 标准化课程
apiurls.standardizationCurriculum_getSumCourseCountEchart = `${appcelapi_api_prefix}/coursePortrait/getSumCourseTypeCountEchart`;
// 课程访问趋势图
apiurls.standardizationCurriculum_getSumCourseMonthlyActionCountEchart = `${appcelapi_api_prefix}/userPortrait/getSumCourseMonthlyActionCountEchart`;
// 课程访问时间段分布
apiurls.standardizationCurriculum_getSumCourseActionTimeDistribution = `${appcelapi_api_prefix}/coursePortrait/getSumCourseActionTimeDistribution`;
// 用户行为数
apiurls.standardizationCurriculum_getSumCourseUserActionNum = `${appcelapi_api_prefix}/coursePortrait/getSumCourseUserActionNum`;

// 素材资源
// 资源库素材建设量排名
apiurls.materialResources_getResoruceZykRank = `${appcelapi_api_prefix}/getResoruceZykRank`;
// 最热资源排名
apiurls.materialResources_getHotResourceCount = `${appcelapi_api_prefix}/getHotResourceCount`;
// 资源历年新增数
apiurls.materialResources_getResourceAddYear = `${appcelapi_api_prefix}/getResourceAddYear`;
// 资源年度访问趋势图
apiurls.materialResources_getResoruceBehaviorCount = `${appcelapi_api_prefix}/getResoruceBehaviorCount`;
// 资源类型占比
apiurls.materialResources_getAllResourceCount = `${appcelapi_api_prefix}/getAllResourceCount`;
// 课程整体情况
apiurls.provinceReport_getCountryReportCourseState = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseState`;
// 资源被课程引用情况
apiurls.materialResources_getCourseRefResoruce = `${appcelapi_api_prefix}/getCourseRefResoruce`;
// 素材资源贡献榜
apiurls.materialResources_getResoruceUploadMoreRankForSchool = `${appcelapi_api_prefix}/getResoruceUploadMoreRankForSchool`;
// 所有级用户分布
apiurls.provinceReport_getAllReportUserOverview = `${appcelapi_api_prefix}/provinceReport/getAllReportUserOverview`;

// 接入情况统计
apiurls.accessSituation_getZykPlatformData = `${appcelapi_api_prefix}/getZykPlatformData`;
apiurls.accessSituation_getZykWebUrlVisit = `${appcelapi_api_prefix}/getZykWebUrlVisit`;
apiurls.accessSituation_getZykPlatformTotalData = `${appcelapi_api_prefix}/getZykPlatformTotalData`;
apiurls.accessSituation_getAllPlatforms = `${appcelapi_api_prefix}/getAllPlatforms`;

// 资源存储
apiurls.resourceStorage_getAllResourceRankByType = `${appcelapi_api_prefix}/ResourceStoragePortrait/getAllResourceRankByType`;
apiurls.resourceStorage_getProvinceFileSizeList = `${appcelapi_api_prefix}/getProvinceFileSizeList`;
apiurls.resourceStorage_getAllResourceCountByType = `${appcelapi_api_prefix}/ResourceStoragePortrait/getAllResourceCountByType`;
apiurls.resourceStorage_getResourceCourseRatio = `${appcelapi_api_prefix}/getResourceCourseRatio`;

// 任务推送
// 上传文件接口
apiurls.task_uploadFile = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/uploadFile`;
// 获取所有平台下拉选项
apiurls.task_getAllPlatforms = `${lrmv2_api_prefix}/getAllPlatforms`;
// 新增或修改平台任务信息
apiurls.task_saveOrUpdateLv2PlatformTaskInfo = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/saveOrUpdateLv2PlatformTaskInfo`;
// 查询平台任务信息列表
apiurls.task_getLv2PlatformTaskInfoEntityList = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/getLv2PlatformTaskInfoEntityList`;
// 下载平台任务文件
apiurls.task_downloadLv2PlatformTaskFileInfo = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/downloadLv2PlatformTaskFileInfo`;
// 删除平台任务信息
apiurls.task_deleteLv2PlatformTaskInfo = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/deleteLv2PlatformTaskInfo`;
// 查询每周监测信息列表
apiurls.task_getLv2PlatformWeekMonitorEntityList = `${lrmv2_api_prefix}/Lv2PlatformTaskInfo/getLv2PlatformWeekMonitorEntityList`;

// 国家整体报告
// 各校等级,覆盖专业
apiurls.monitoringCloud_getSchoolCollegeLevel = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getSchoolCollegeLevel`;
// 用户分布
apiurls.provinceReport_getCountryReportUserOverview = `${appcelapi_api_prefix}/provinceReport/getCountryReportUserOverview`;
// 用户类型分布
apiurls.monitoringCloud_getUserTypeDistribute = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getUserTypeDistribute`;
// 素材资源,资源存储量
apiurls.monitoringCloud_getResourceCount = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getResourceCount`;
// 在线学习人数排名
apiurls.overview_getOnlineStudyUserNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getOnlineStudyUserNumRank`;
// 交流互动量排名
apiurls.overview_getInteractionNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getInteractionNumRank`;
// 课程数量分布
apiurls.monitoringCloud_getSchoolCourseProvinceRanking = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getSchoolCourseProvinceRanking`;
// 资源建设排名
apiurls.professional_getResourceRanking = `${lrmv2_api_prefix}/leaderResourceBase/getResourceRanking`;
// 获取省份资源访问数排名 (前15名)
apiurls.provinceReport_getCountryReportProvinceResourceRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportProvinceResourceRank`;
// 获取课程近一年访问趋势
apiurls.provinceReport_getCountryReportUserMonthVisits = `${appcelapi_api_prefix}/provinceReport/getCountryReportUserMonthVisits`;
// 数据总览
apiurls.provinceReport_getCountryReportOverview = `${appcelapi_api_prefix}/provinceReport/getCountryReportOverview`;
// 省份教师数 (前15名)
apiurls.provinceReport_getCountryReportProvinceTeacherRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportProvinceTeacherRank`;
// 获取省份用户数排名 前（15名）
apiurls.provinceReport_getCountryReportProvinceUserRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportProvinceUserRank`;
// 获取课程近一年访问趋势
apiurls.provinceReport_getCountryReportCourseMonthVisits = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseMonthVisits`;
// 区间内资源库课程访问量排名
apiurls.provinceReport_getCountryReportZyKCourseRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKCourseRank`;
// 获取区间内每月新增用户数
apiurls.provinceReport_getCountryReportUserMonthNew = `${appcelapi_api_prefix}/provinceReport/getCountryReportUserMonthNew`;
// 获取区间内每月访问趋势
apiurls.provinceReport_getCountryReportUserMonthVisitsRange = `${appcelapi_api_prefix}/provinceReport/getCountryReportUserMonthVisitsRange`;

// 课程被访问量排名（前十五名）
apiurls.provinceReport_getCountryReportCourseRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseRank`;
// 获取资源访问量排名（前十五名）
apiurls.provinceReport_getCountryReportResourceRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportResourceRank`;
// 获取区间内每月课程，资源新增和访问量趋势
apiurls.provinceReport_getCountryReportMonthRange = `${appcelapi_api_prefix}/provinceReport/getCountryReportMonthRange`;

// 2. 专业教学资源库应用分析
apiurls.resourceBaseMonitoringCloudMap_getResourceBaseLevel = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getResourceBaseLevel`;
// 用户建设及应用情况
apiurls.resourceBaseMonitoringCloudMap_getCoreIndicators = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getCoreIndicators`;
// 资源库省份用户建设情况
apiurls.provinceReport_getCountryReportZykUserProvinceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZykUserProvinceStatus`;
// 获取访问总体情况
apiurls.provinceReport_getCountryReportTotalLogModule = `${appcelapi_api_prefix}/provinceReport/getCountryReportTotalLogModule`;
// 学生类型整体情况
apiurls.userPortrait_getSumStudentTypeEchart = `${appcelapi_api_prefix}/userPortrait/getSumStudentTypeEchart`;
// 获取国家端-课程画像-课程数量图
apiurls.coursePortrait_getSumCourseTypeCountEchart = `${appcelapi_api_prefix}/coursePortrait/getSumCourseTypeCountEchart`;
// 获取资源库专业大类分布情况
apiurls.provinceReport_getCountryReportZykProfession = `${appcelapi_api_prefix}/provinceReport/getCountryReportZykProfession`;
// 资源库访问情况排名（前十五名）
apiurls.provinceReport_getCountryReportZykLogRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportZykLogRank`;
// 资源库活跃用户数排名 （前十五名）
apiurls.provinceReport_getCountryReportZykActiveUserRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportZykActiveUserRank`;
// 各省用户建设及应用
apiurls.provinceReport_getCountryReportZykUserProvinceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZykUserProvinceStatus`;
// 资源库省份课程建设情况
apiurls.provinceReport_getCountryReportZyKCourseProvinceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKCourseProvinceStatus`;
// 资源库省份资源建设情况
apiurls.provinceReport_getCountryReportZyKResourceProvinceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKResourceProvinceStatus`;
// 资源库用户运行情况
apiurls.provinceReport_getCountryReportZyKUserActiveStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKUserActiveStatus`;
// 资源库资源运行情况
apiurls.provinceReport_getCountryReportZyKResourceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKResourceStatus`;
// 资源库年报_按专业大类分布
apiurls.provinceReport_getZykProfessionClassify = `${appcelapi_api_prefix}/provinceReport/getZykProfessionClassify`;
// 资源库年报_按省份分布
apiurls.provinceReport_getZykProvinceClassify = `${appcelapi_api_prefix}/provinceReport/getZykProvinceClassify`;
// （4）资源库被用户访问情况排名分析
apiurls.provinceReport_getCountryReportZyKVisitsRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportZyKVisitsRank`;

// 获取区间内国家级资源库新增用户数
apiurls.provinceReport_getCountryReportCountryZykUserMonthNew = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZykUserMonthNew`;
// 国家级资源库用户月活，访问情况
apiurls.provinceReport_getCountryReportCountryZyKUserActiveStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZyKUserActiveStatus`;
// 国家级资源库访问情况排名
apiurls.provinceReport_getCountryReportCountryZyKVisitsRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZyKVisitsRank`;
// 获取区间内国家级资源库课程新增趋势
apiurls.provinceReport_getCountryReportCountryZykMonthRange = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZykMonthRange`;
// 区间内国家级资源库课程访问量排名
apiurls.provinceReport_getCountryReportCountryZyKCourseRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZyKCourseRank`;
// 国家级资源库资源运行情况
apiurls.provinceReport_getCountryReportCountryZyKResourceStatus = `${appcelapi_api_prefix}/provinceReport/getCountryReportCountryZyKResourceStatus`;
// 获取203国家级资源库用户总访问量
apiurls.getCountryZykVisitCaseTotalByType = `${appcelapi_api_prefix}/getCountryZykVisitCaseTotalByType`;
// 获取三个级别资源库用户总访问量
apiurls.getZykVisitCaseTotalByType = `${appcelapi_api_prefix}/getZykVisitCaseTotalByType`;

// 3.在线精品课应用分析
apiurls.coursePortrait_getCourseCountByLevel = `${appcelapi_api_prefix}/coursePortrait/getCourseCountByLevel`;
// 平台数量和平台课程数（前三）
apiurls.coursePortrait_getCourseCountByPlatform = `${appcelapi_api_prefix}/coursePortrait/getCourseCountByPlatform`;
//  获取核心指标
apiurls.coursePortrait_getCoreIndicatorStat = `${appcelapi_api_prefix}/coursePortrait/getCoreIndicatorStat`;
//  在线精品课_参加课程数量各省排名
apiurls.provinceReport_getCountryReportCourseProvinceRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseProvinceRank`;
// 课程总访问量排名
apiurls.coursePortrait_getCourseVisitRank = `${appcelapi_api_prefix}/coursePortrait/getCourseVisitRank`;
// 课程开课期数
apiurls.provinceReport_getCountryReportCourseSemesterRank = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseSemesterRank`;
// 课程选课人次排名
apiurls.coursePortrait_getCourseStudyRank = `${appcelapi_api_prefix}/coursePortrait/getCourseStudyRank`;
// 精品课_选课人数小于1000
apiurls.courseReport_getCourseReportStudyNumLessRank = `${appcelapi_api_prefix}/courseReport/getCourseReportStudyNumLessRank`;
// 在线精品课、视频公开课总平台数
apiurls.courseReport_getOnlineCourseTotalPlatformCount = `${appcelapi_api_prefix}/courseReport/getOnlineCourseTotalPlatformCount`;
// 省级整体报告
// 数据总览
apiurls.provinceReport_getProvinceReportOverview = `${appcelapi_api_prefix}/provinceReport/getProvinceReportOverview`;
// 用户数据
apiurls.provinceReport_getProvinceReportUserCount = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserCount`;
// 用户建设及应用
apiurls.provinceReport_getProvinceReportUserBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserBuild`;
// 学生老师用户访问量
apiurls.provinceReport_getProvinceReportUserVisits = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserVisits`;
// 课程建设情况
apiurls.provinceReport_getProvinceReportCourseBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCourseBuild`;
// 课程专业大类建设情况
apiurls.provinceReport_getProvinceReportCourseBuildClass = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCourseBuildClass`;
// 近一年课程被访问总量
apiurls.provinceReport_getProvinceReportCourseMonthVisits = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCourseMonthVisits`;
// 课程访问量排名
apiurls.provinceReport_getProvinceReportCourseRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCourseRank`;
//  资源建设情况
apiurls.provinceReport_getProvinceReportResourceBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportResourceBuild`;
// 参与教师数排名
apiurls.resourceBaseMonitoringCloudMap_getProvinceTeacherNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getProvinceTeacherNumRank`;
// 参与学生数排名
apiurls.resourceBaseMonitoringCloudMap_getProvinceStudentNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getProvinceStudentNumRank`;
// 参与交流数排名
apiurls.resourceBaseMonitoringCloudMap_getProvinceInteractionNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getProvinceInteractionNumRank`;
// 开设课程量排名
apiurls.resourceBaseMonitoringCloudMap_getProvinceCourseNumRank = `${lrmv2_api_prefix}/resourceBaseMonitoringCloudMap/getProvinceCourseNumRank`;
// 院校资源建设及应用情况
apiurls.provinceReport_getProvinceReportSchoolResourceBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportSchoolResourceBuild`;
// 院校资源访问量排名
apiurls.provinceReport_getProvinceReportSchoolResourceVisitsRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportSchoolResourceVisitsRank`;
// 区间内新增用户，课程，资源数量趋势
apiurls.provinceReport_getProvinceReportNewBuildRange = `${appcelapi_api_prefix}/provinceReport/getProvinceReportNewBuildRange`;
// 资源库总体情况
apiurls.provinceReport_getProvinceReportZykOverview = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykOverview`;
// 资源库用户建设情况
apiurls.provinceReport_getProvinceReportZykUser = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykUser`;
// 院校注册用户数及活跃用户数
apiurls.provinceReport_getProvinceReportUserActiveTotal = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserActiveTotal`;
// 资源库用户访问量
apiurls.provinceReport_getProvinceReportZykVisitsRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykVisitsRank`;
// 院校活跃用户数排名
apiurls.provinceReport_getProvinceReportUserActiveRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserActiveRank`;
// 资源库课程建设情况
apiurls.provinceReport_getProvinceReportZykCourseBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykCourseBuild`;
// 资源库课程建设情况
apiurls.provinceReport_getProvinceReportZykBigClassBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykBigClassBuild`;
// 资源库用户运行情况
apiurls.provinceReport_getProvinceReportZykActiveUserReign = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykActiveUserReign`;
// 资源库用户新增
apiurls.provinceReport_getProvinceReportUserNewBuildRange = `${appcelapi_api_prefix}/provinceReport/getProvinceReportUserNewBuildRange`;
// 资源库活跃用户数排名
apiurls.provinceReport_getProvinceReportZykActiveUserRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykActiveUserRank`;

// 资源库访问情况排名
apiurls.provinceReport_getProvinceReportZykVisitsReign = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykVisitsReign`;

// 资源库访问量排名
apiurls.provinceReport_getProvinceReportZykCourseReignRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykCourseReignRank`;
// 资源库课程运行情况

// 资源库资源运行情况
apiurls.provinceReport_getProvinceReportZykResourceStatus = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykResourceStatus`;

// 在线精品课程建设及应用情况
apiurls.provinceReport_getProvinceReportOnlineCourseStat = `${appcelapi_api_prefix}/provinceReport/getProvinceReportOnlineCourseStat`;
// 在线精品课_课程分级数量
apiurls.provinceReport_getProvinceReportOnlineCourseCountByLevel = `${appcelapi_api_prefix}/provinceReport/getProvinceReportOnlineCourseCountByLevel`;
// 参见课程数量排名
apiurls.provinceReport_getProvinceReportOnlineCourseRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportOnlineCourseRank`;
// 在线精品课_课程专业大类分布
apiurls.provinceReport_getProvinceReportOnlineCourseCategoryBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportOnlineCourseCategoryBuild`;

// 国家级在线精品课和视频公开课
// 查询精品课和公开课的数量
apiurls.courseReport_getCourseReportTotal = `${appcelapi_api_prefix}/courseReport/getCourseReportTotal`;
// 数据总览
apiurls.courseReport_getCourseReportOverview = `${appcelapi_api_prefix}/courseReport/getCourseReportOverview`;
apiurls.courseReport_getCourseReportProvinceRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceRank`;
apiurls.courseReport_getCourseReportTearcherRank = `${appcelapi_api_prefix}/courseReport/getCourseReportTearcherRank`;
// 课程访问量排名
apiurls.courseReport_getCourseReportVisitRank = `${appcelapi_api_prefix}/courseReport/getCourseReportVisitRank`;
// 选课情况排名
apiurls.courseReport_getCourseReportStudyRank = `${appcelapi_api_prefix}/courseReport/getCourseReportStudyRank`;
apiurls.courseReport_getCourseReportProvinceVisitRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceVisitRank`;
// 课程选课人数排名
apiurls.courseReport_getCourseReportProvinceVisitRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceVisitRank`;
// 课程选课人数
apiurls.courseReport_getCourseReportProvinceStudyRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceStudyRank`;
// 课程互动情况排名
apiurls.courseReport_getCourseReportSemesterRank = `${appcelapi_api_prefix}/courseReport/getCourseReportSemesterRank`;
apiurls.courseReport_getCourseReportProvinceSemesterRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceSemesterRank`;

// 区间内课程访问情况
apiurls.courseReport_getCourseReportRegionVisitsRank = `${appcelapi_api_prefix}/courseReport/getCourseReportRegionVisitsRank`;
// 建设课程数量专业大类分布
apiurls.courseReport_getCountryReportCourseCategoryBuild = `${appcelapi_api_prefix}/provinceReport/getCountryReportCourseCategoryBuild`;
// 2.1.1.在线精品课建设情况
apiurls.courseReport_getCourseReportProvinceCreateRank = `${appcelapi_api_prefix}/courseReport/getCourseReportProvinceCreateRank`;

// （学生应用报告 + 教师）批量导出报告
apiurls.exportBatchReport = `${exportBatch_api_exportBatch}/exportBatchReport`;
//  学生应用报告 + 教师）批量导出报告
apiurls.downloadReport = `${downloadReport}`;
// 年报-年份数据对比分析
apiurls.getYearReportByDataAnalysis = `${lrm_api_prefix}/Lv2ReportFile/getYearReportByDataAnalysis`;
// 个性化年报-资源库全国数据、基本数据分析
apiurls.getYearReportByDataAnalysisContent = `${lrm_api_prefix}/Lv2ReportFile/getYearReportByDataAnalysisContent`;
// 其他运行数据基本报告_用户、资源、课程指标
apiurls.getOtherBasicReportUserVo = `${lrm_api_prefix}/Lv2ReportFile/getOtherBasicReportUserVo`;
// 其他运行数据基本报告_历年新增用户、资源数
apiurls.getOtherBasicReportResourceOrUserUpdateVo = `${lrm_api_prefix}/Lv2ReportFile/getOtherBasicReportResourceOrUserUpdateVo`;

// 省级资源库数据总览
apiurls.getProvinceReportCountryOverview = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryOverview`;
// 资源库用户建设情况
apiurls.getProvinceCountryReportZykUser = `${appcelapi_api_prefix}/provinceReport/getProvinceCountryReportZykUser`;
// 课程建设情况
apiurls.getProvinceReportCountryZykCourseBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryZykCourseBuild`;
// 国家级资源库-资源建设情况
apiurls.getProvinceReportCountryResourceBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryResourceBuild`;
// 国家级资源库运行详情
apiurls.getProvinceReportCountryZykDetail = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryZykDetail`;
// 资源库课程大类建设情况
apiurls.getProvinceReportCountryZykBigClassBuild = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryZykBigClassBuild`;
// 国家级资源库排名
apiurls.getProvinceReportCountryZykRank = `${appcelapi_api_prefix}/provinceReport/getProvinceReportCountryZykRank`;
// 国家级资源库-获取访问总体情况
apiurls.getProvinceReportTotalLogModule = `${appcelapi_api_prefix}/provinceReport/getProvinceReportTotalLogModule`;
// 批量文件导入
apiurls.uploadFile = `${lrmv2_api_prefix}/uploadFile`;
// 获取总资源素材类型占比情况
apiurls.getTotalResourceCount = `${appcelapi_api_prefix}/getTotalResourceCount`;

/**
 * 国家端监测运行年报
 */
// 1. 监测总览
apiurls.getOverviewData = `${appcelapi_api_prefix}/getOverviewData`;
// 1.1.1 用户建设及应用
apiurls.getUserBuildApply = `${appcelapi_api_prefix}/getUserBuildApply`;
// 1.1.2 课程建设及应用
apiurls.getCourseBuildApply = `${appcelapi_api_prefix}/getCourseBuildApply`;
// 1.1.3 资源建设及应用
apiurls.getResourceBuildApply = `${appcelapi_api_prefix}/getResourceBuildApply`;

// 1.2.1 各省用户建设及应用情况
apiurls.getProvinceUserBuildApply = `${appcelapi_api_prefix}/getProvinceUserBuildApply`;
// 1.2.2 各省课程建设及应用
apiurls.getProvinceCourseBuildApply = `${appcelapi_api_prefix}/getProvinceCourseBuildApply`;
// 1.2.3 各省资源建设及应用
apiurls.getProvinceResourceBuildApply = `${appcelapi_api_prefix}/getProvinceResourceBuildApply`;
// 1.3.1 用户运行分析
apiurls.getUserRunAnalysis = `${appcelapi_api_prefix}/getUserRunAnalysis`;
// 1.3.2 课程运行分析
apiurls.getCourseRunAnalysis = `${appcelapi_api_prefix}/getCourseRunAnalysis`;
// 1.3.3 资源运行分析
apiurls.getResourceRunAnalysis = `${appcelapi_api_prefix}/getResourceRunAnalysis`;

// 2.1 203个国家级资源库建设与应用分析
apiurls.getCountryAllZykBuildAndAppAnalysis = `${appcelapi_api_prefix}/getCountryAllZykBuildAndAppAnalysis`;
// 2.1.1 用户建设及应用分析
apiurls.getCountryUserBuildAndAppAnalysis = `${appcelapi_api_prefix}/getCountryUserBuildAndAppAnalysis`;

// 2.1.2 课程建设及应用分析
apiurls.getCourseBuildAndAppAnalysis = `${appcelapi_api_prefix}/getCourseBuildAndAppAnalysis`;

// 2.1.3 资源建设及应用分析
apiurls.getResourceBuildAndAppAnalysis = `${appcelapi_api_prefix}/getResourceBuildAndAppAnalysis`;
// 2.1.4 按专业大类汇总统计维度分析
apiurls.getMajorCountAnalysis = `${appcelapi_api_prefix}/getMajorCountAnalysis`;
// 2.1.4.1~2.1.4.4 资源库专业大类排名前四运行分析报告
apiurls.getTopFourProfessionAnalysis = `${appcelapi_api_prefix}/getTopFourProfessionAnalysis`;
apiurls.getTopFourProfessionVisitBar = `${appcelapi_api_prefix}/getTopFourProfessionVisitBar`;
// /2.1.5.1 各省国家级资源库建设与应用情况概览
apiurls.getCountryZykProvinceBuildAndAnalysisOutline = `${appcelapi_api_prefix}/getCountryZykProvinceBuildAndAnalysisOutline`;

// 2.1.5.2 各省国家级资源库用户建设与应用情况
apiurls.getCountryZykProvinceUserBuildAndAnalysis = `${appcelapi_api_prefix}/getCountryZykProvinceUserBuildAndAnalysis`;

// 2.1.5.3 各省国家级资源库课程建设及应用情况
apiurls.getCountryZykProvinceCourseBuildAndAnalysisCase = `${appcelapi_api_prefix}/getCountryZykProvinceCourseBuildAndAnalysisCase`;

// 2.1.5.4 各省国家级资源库资源建设及应用情况
apiurls.getCountryProvinceZykBuildAndAnalysisCase = `${appcelapi_api_prefix}/getCountryProvinceZykBuildAndAnalysisCase`;

// 2.1.6.1 国家级资源库社会学习者新增情况
apiurls.countryZykSocietyUserNewAddCase = `${appcelapi_api_prefix}/countryZykSocietyUserNewAddCase`;
// 2.1.6.1 国家级资源库学生新增情况
apiurls.getCountryZykStudentUserNewAddCase = `${appcelapi_api_prefix}/getCountryZykStudentUserNewAddCase`;
// 2.1.6.1 国家级资源库教师新增情况
apiurls.getCountryZykTeacherUserNewAddCase = `${appcelapi_api_prefix}/getCountryZykTeacherUserNewAddCase`;
// 2.1.6.1 国家级资源库企业用户新增情况
apiurls.getCountryZykEnterpriseUserNewAddCase = `${appcelapi_api_prefix}/getCountryZykEnterpriseUserNewAddCase`;
// 2.1.6.2 国家级资源库资源新增情况
apiurls.getCountryZykResourceNewAddCase = `${appcelapi_api_prefix}/getCountryZykResourceNewAddCase`;
// 2.1.6.3 国家级资源库课程新增情况
apiurls.getCountryZykCourseNewAddCase = `${appcelapi_api_prefix}/getCountryZykCourseNewAddCase`;
// 2.1.6.4 国家级资源库学生访问情况
apiurls.getCountryZykStudentUserVisitCase = `${appcelapi_api_prefix}/getCountryZykStudentUserVisitCase`;
// 2.1.6.4 国家级资源库教师访问情况
apiurls.getCountryZykTeacherUserVisitCase = `${appcelapi_api_prefix}/getCountryZykTeacherUserVisitCase`;
// 2.1.6.4 国家级资源库企业用户访问情况
apiurls.getCountryZykEnterpriseUserVisitCase = `${appcelapi_api_prefix}/getCountryZykEnterpriseUserVisitCase`;
// 2.1.6.4 国家级资源库社会学习者访问情况
apiurls.getCountryZykSocietyUserVisitCase = `${appcelapi_api_prefix}/getCountryZykSocietyUserVisitCase`;
// 2.1.6.5 国家级资源库课程访问情况
apiurls.getCountryZykCourseVisitCase = `${appcelapi_api_prefix}/getCountryZykCourseVisitCase`;
// 2.1.6.6 国家级资源库2022年资源访问情况
apiurls.getCountryZykResourceVisitCase = `${appcelapi_api_prefix}/getCountryZykResourceVisitCase`;
// 2.1.7.1 按资源量维度排名
apiurls.getYearReportCountryZykActiveResourceRank = `${appcelapi_api_prefix}/getYearReportCountryZykActiveResourceRank`;
// 2.1.7.2 按访问量维度排名
apiurls.getCountryZykBuildAnalysisLogRank = `${appcelapi_api_prefix}/getCountryZykBuildAnalysisLogRank`;
// 2.1.7.3 按库内活跃用户数维度排名
apiurls.getYearReportCountryZykActiveUserRank = `${appcelapi_api_prefix}/getYearReportCountryZykActiveUserRank`;
// 2.1.7.4 2022年综合应用排行排名
apiurls.getCompositeRank = `${appcelapi_api_prefix}/getCompositeRank`;

// 2.1.8 203个国家级资源库覆盖2021版专业目录情况分析
apiurls.getCountryZykCoverProfessionAnalysis = `${appcelapi_api_prefix}/getCountryZykCoverProfessionAnalysis`;

// 2.2 国家及省市校级专业教学资源库运行监测情况分析
apiurls.getCountryProvinceCitySchoolLevelProfessionZykRunCase = `${appcelapi_api_prefix}/getCountryProvinceCitySchoolLevelProfessionZykRunCase`;
// 2.2.1 用户建设及应用
apiurls.getUserBuildAndAnalysis = `${appcelapi_api_prefix}/getUserBuildAndAnalysis`;
// 2.2.2 课程建设及应用
apiurls.getCourseBuildAndAnalysis = `${appcelapi_api_prefix}/getCourseBuildAndAnalysis`;
// 2.2.3 资源建设及应用
apiurls.getResourceBuildAndAnalysis = `${appcelapi_api_prefix}/getResourceBuildAndAnalysis`;
// 2.2.4 各省资源库建设与应用情况
// 2.2.4.1 各省资源库建设与应用情况概览
apiurls.getProvinceZykBuildAndAnalysis = `${appcelapi_api_prefix}/getProvinceZykBuildAndAnalysis`;
// 2.2.4.2 各省用户建设与应用情况
apiurls.getProvinceUserBuildAndAnalysis = `${appcelapi_api_prefix}/getProvinceUserBuildAndAnalysis`;
// 2.2.4.3 各省课程建设及应用情况
apiurls.getProvinceCourseBuildAndAnalysis = `${appcelapi_api_prefix}/getProvinceCourseBuildAndAnalysis`;
// 2.2.4.4 各省资源建设及应用情况
apiurls.getProvinceZyBuildAndAnalysis = `${appcelapi_api_prefix}/getProvinceZyBuildAndAnalysis`;
// 2.2.5.1 资源库学生用户新增情况
apiurls.getZykStudentUserNewAddCase = `${appcelapi_api_prefix}/getZykStudentUserNewAddCase`;
// 2.2.5.1 资源库教师用户新增情况
apiurls.getZykTeacherUserNewAddCase = `${appcelapi_api_prefix}/getZykTeacherUserNewAddCase`;
//  2.2.5.1 资源库企业用户新增情况
apiurls.getZykEnterpriseUserNewAddCase = `${appcelapi_api_prefix}/getZykEnterpriseUserNewAddCase`;
//  2.2.5.1 资源库社会学习者用户新增情况
apiurls.getZykSocietyUserNewAddCase = `${appcelapi_api_prefix}/getZykSocietyUserNewAddCase`;
// 2.2.5.2 资源库资源新增情况
apiurls.getZykResourceNewAddCase = `${appcelapi_api_prefix}/getZykResourceNewAddCase`;
// 2.2.5.3 资源库课程新增情况
apiurls.getZykCourseNewAddCase = `${appcelapi_api_prefix}/getZykCourseNewAddCase`;
// 2.2.5.4 资源库学生访问情况
apiurls.getZykStudentUserVisitCase = `${appcelapi_api_prefix}/getZykStudentUserVisitCase`;
// 2.2.5.4 资源库教师访问情况
apiurls.getZykTeacherUserVisitCase = `${appcelapi_api_prefix}/getZykTeacherUserVisitCase`;
// 2.2.5.4 资源库企业用户访问情况
apiurls.getZykEnterpriseUserVisitCase = `${appcelapi_api_prefix}/getZykEnterpriseUserVisitCase`;
// 2.2.5.4 资源库社会学习者访问情况
apiurls.getZykSocietyUserVisitCase = `${appcelapi_api_prefix}/getZykSocietyUserVisitCase`;
// 2.2.5.5 资源库课程访问情况
apiurls.getZykCourseVisitCase = `${appcelapi_api_prefix}/getZykCourseVisitCase`;
// 2.2.5.6  资源库资源访问情况
apiurls.getZykResourceVisitCase = `${appcelapi_api_prefix}/getZykResourceVisitCase`;
// 2.2.6.1 资源量排名
apiurls.getYearReportZykActiveResourceRank = `${appcelapi_api_prefix}/getYearReportZykActiveResourceRank`;
// 2.2.6.2 访问量排名
apiurls.getZykBuildAnalysisLogRank = `${appcelapi_api_prefix}/getZykBuildAnalysisLogRank`;
// 2.2.6.3  活跃用户数排名
apiurls.getYearReportZykActiveUserRank = `${appcelapi_api_prefix}/getYearReportZykActiveUserRank`;
// 3.1 在线精品课 - 年度运行监测总体情况
apiurls.getOnlineCourseYearRunAnalysis = `${appcelapi_api_prefix}/getOnlineCourseYearRunAnalysis`;
// 3.1.1.1 各省参建课程数量
apiurls.getProvinceBuildCourseNum = `${appcelapi_api_prefix}/getProvinceBuildCourseNum`;
// 3.1.1.2 归属专业大类分布情况
apiurls.getProfessionBigClassCourseNum = `${appcelapi_api_prefix}/getProfessionBigClassCourseNum`;
// 3.1.2 在线精品课访问数据
apiurls.getOnlineCourseVisitNum = `${appcelapi_api_prefix}/getOnlineCourseVisitNum`;
// 3.1.3 在线精品课开课数据
apiurls.getOnlineCourseOpenNum = `${appcelapi_api_prefix}/getOnlineCourseOpenNum`;
// 3.1.4 在线精品课程选课数据
apiurls.getOnlineCourseSelectNum = `${appcelapi_api_prefix}/getOnlineCourseSelectNum`;
// 4、视频公开课运行监测情况分析
apiurls.getVideoCourseRunAnalysis = `${appcelapi_api_prefix}/getVideoCourseRunAnalysis`;
// 4.1 各省视频公开课建设情况
apiurls.getProvinceVideoCourseBuild = `${appcelapi_api_prefix}/getProvinceVideoCourseBuild`;
// 4.2 视频公开课访问情况
apiurls.getVideoCourseVisit = `${appcelapi_api_prefix}/getVideoCourseVisit`;
// 4.3 视频公开课选课情况
apiurls.getVideoCourseSelect = `${appcelapi_api_prefix}/getVideoCourseSelect`;

// 国家端-整体月报相关接口
// 1. 监测总览
apiurls.getCountryMonthReportOverviewData = `${appcelapi_api_prefix}/getCountryMonthReportOverviewData`;
// 1.1.1 用户建设及应用情况
apiurls.getUserBuildApplyCase = `${appcelapi_api_prefix}/getUserBuildApplyCase`;
// 1.1.2 课程建设及应用情况
apiurls.getCourseBuildApplyCase = `${appcelapi_api_prefix}/getCourseBuildApplyCase`;
// 1.1.3 资源建设及应用
apiurls.getResourceBuildApplyCase = `${appcelapi_api_prefix}/getResourceBuildApplyCase`;
// 1.2.1 各省用户建设及应用
apiurls.getProvinceUserBuildApplyCase = `${appcelapi_api_prefix}/getProvinceUserBuildApplyCase`;
// 1.2.2 各省课程建设及应用
apiurls.getProvinceCourseBuildApplyCase = `${appcelapi_api_prefix}/getProvinceCourseBuildApplyCase`;
// 1.2.3 各省资源建设及应用
apiurls.getProvinceResourceBuildApplyCase = `${appcelapi_api_prefix}/getProvinceResourceBuildApplyCase`;
// 1.3.1 用户建设及应用总体情况课程建设及应用总体情况
apiurls.getUserRunAnalysisCase = `${appcelapi_api_prefix}/getUserRunAnalysisCase`;
// 1.3.2 课程建设及应用总体情况
apiurls.getCourseRunAnalysisCase = `${appcelapi_api_prefix}/getCourseRunAnalysisCase`;
// 1.3.3 资源运行分析
apiurls.getResourceRunAnalysisCase = `${appcelapi_api_prefix}/getResourceRunAnalysisCase`;
// 2.1 资源库建设及应用总体情况
apiurls.getAllZykBuildAndAppAnalysisCase = `${appcelapi_api_prefix}/getAllZykBuildAndAppAnalysisCase`;
// 2.1.1 用户建设及应用分析
apiurls.getUserBuildAndAnalysisCase = `${appcelapi_api_prefix}/getUserBuildAndAnalysisCase`;
// 2.1.2 课程建设及应用情况
apiurls.getCourseBuildAndAnalysisCase = `${appcelapi_api_prefix}/getCourseBuildAndAnalysisCase`;
// 2.1.3 资源建设及应用情况
apiurls.getResourceBuildAndAnalysisCase = `${appcelapi_api_prefix}/getResourceBuildAndAnalysisCase`;
// 2.1.4.1 覆盖专业大类分析
apiurls.getCoverProfessionList = `${appcelapi_api_prefix}/getCoverProfessionList`;
// 2.1.4.2 用户建设及应用情况
apiurls.getCountryUserBuildAnalysisCase = `${appcelapi_api_prefix}/getCountryUserBuildAnalysisCase`;
// 2.1.4.3 课程建设及应用情况
apiurls.getCountryCourseBuildAnalysisCase = `${appcelapi_api_prefix}/getCountryCourseBuildAnalysisCase`;
// 2.1.4.4 资源建设及应用情况
apiurls.getCountryResourceBuildAnalysis = `${appcelapi_api_prefix}/getCountryResourceBuildAnalysis`;
// 2.1.4.5.1 按照访问量排名
apiurls.getCountryZykVisitLogRank = `${appcelapi_api_prefix}/getCountryZykVisitLogRank`;
// 2.1.4.5.2 按照用户活跃情况分析
apiurls.getCountryZykUserActiveAnalysis = `${appcelapi_api_prefix}/getCountryZykUserActiveAnalysis`;
// 2.2.1 各省用户建设及应用情况
apiurls.getProvinceUserBuildAnalysis = `${appcelapi_api_prefix}/getProvinceUserBuildAnalysis`;
// 2.2.2 各省课程建设及应用情况
apiurls.getProvinceCourseBuildAnalysis = `${appcelapi_api_prefix}/getProvinceCourseBuildAnalysis`;
// 2.2.3 各省资源建设及应用情况
apiurls.getProvinceZyBuildAnalysis = `${appcelapi_api_prefix}/getProvinceZyBuildAnalysis`;
// 2.3.1 资源库用户建设及应用情况 1. 用户新增情况
apiurls.getZykUserAddCase = `${appcelapi_api_prefix}/getZykUserAddCase`;
// 2.3.1 资源库用户建设及应用情况 2. 用户活跃情况
apiurls.getZykUserActiveCase = `${appcelapi_api_prefix}/getZykUserActiveCase`;
// 2.3.1 资源库用户建设及应用情况 3. 用户访问情况排名
apiurls.getUserVisitRank = `${appcelapi_api_prefix}/getUserVisitRank`;
// 2.3.2 资源库课程建设及应用情况
apiurls.getZykCourseBuildAnalysis = `${appcelapi_api_prefix}/getZykCourseBuildAnalysis`;
// 2.3.3 资源库资源建设及应用情况
apiurls.getZykResourceBuildAnalysis = `${appcelapi_api_prefix}/getZykResourceBuildAnalysis`;
// 2.3.4.1 用户运行情况
apiurls.getCountryZykUserRunCase = `${appcelapi_api_prefix}/getCountryZykUserRunCase`;
// 2.3.4.2 课程运行情况
apiurls.getCountryCourseRunCase = `${appcelapi_api_prefix}/getCountryCourseRunCase`;
// 2.3.4.3 资源运行情况
apiurls.getCountryZykResourceRunCase = `${appcelapi_api_prefix}/getCountryZykResourceRunCase`;
// 3.在线精品课建设及应用情况
apiurls.getOnlineCourseBuildAnalysis = `${appcelapi_api_prefix}/monthReport/getOnlineCourseBuildAnalysis`;
// 3.1 各省在线精品课建设情况
apiurls.getProvinceBuildCourseNum = `${appcelapi_api_prefix}/monthReport/getProvinceBuildCourseNum`;
// 3.2 在线精品课访问情况
apiurls.newGetOnlineCourseVisitNum = `${appcelapi_api_prefix}/monthReport/getOnlineCourseVisitNum`;
// 3.3 在线精品课开课情况
apiurls.getOnlineCourseOpenNum = `${appcelapi_api_prefix}/monthReport/getOnlineCourseOpenNum`;
// 3.4 在线精品课程选课情况
apiurls.getOnlineCourseSelectNum = `${appcelapi_api_prefix}/monthReport/getOnlineCourseSelectNum`;
// 4、视频公开课运行监测情况分析
apiurls.getVideoCourseRunAnalysis = `${appcelapi_api_prefix}/monthReport/getVideoCourseRunAnalysis`;
// 4.1 各省视频公开课建设情况
apiurls.getProvinceVideoCourseBuild = `${appcelapi_api_prefix}/monthReport/getProvinceVideoCourseBuild`;
// 4.2 视频公开课访问情况
apiurls.getVideoCourseVisit = `${appcelapi_api_prefix}/monthReport/getVideoCourseVisit`;
// 4.3 视频公开课选课情况
apiurls.getVideoCourseSelect = `${appcelapi_api_prefix}/monthReport/getVideoCourseSelect`;

// 平台推送数据管理
// 平台推送数据查看
apiurls.getDataInfo = `${lrmv2_api_prefix}/pushDataManage/getDataInfo`;
// 平台推送数据处理
apiurls.processData = `${lrmv2_api_prefix}/pushDataManage/processData`;

// 专业教学标准管理
// 专业教学标准表信息列表
apiurls.getProfessionStandardInfo = `${lrmv2_api_prefix}/getProfessionStandardInfo`;
// 新增专业教学标准表信息
apiurls.addProfessionStandardInfo = `${lrmv2_api_prefix}/addProfessionStandardInfo`;
// 修改专业教学标准表信息
apiurls.editProfessionStandardInfo = `${lrmv2_api_prefix}/editProfessionStandardInfo`;
// 删除专业教学标准表信息
apiurls.delProfessionStandardInfo = `${lrmv2_api_prefix}/delProfessionStandardInfo`;

// 查询三级两平台表信息
apiurls.getLv2ProfessionZykInfo = `${lrmv2_api_prefix}/getLv2ProfessionZykInfo`;
// 新增三级两平台表信息
apiurls.addLv2ProfessionZykInfo = `${lrmv2_api_prefix}/addLv2ProfessionZykInfo`;
// 修改三级两平台表信息
apiurls.editLv2ProfessionZykInfo = `${lrmv2_api_prefix}/editLv2ProfessionZykInfo`;
// 删除三级两平台表信息
apiurls.delLv2ProfessionZykInfo = `${lrmv2_api_prefix}/delLv2ProfessionZykInfo`;
//查看密钥
apiurls.getZykKey = `${lrmv2_api_prefix}/getZykKey`;
//下发密钥
apiurls.distributeZykIdKey = `${lrmv2_api_prefix}/distributeZykIdKey`;
//删除密钥
apiurls.delZykKey = `${lrmv2_api_prefix}/delZykKey`;
//三级两平台 -纳入监测
apiurls.setMonitorFlag = `${lrmv2_api_prefix}/setMonitorFlag`;
// 导出资源库秘钥表详情
apiurls.exportZykKeyInfo = `${lrmv2_api_prefix}/exportZykKeyInfo`;
// 批量导入
apiurls.importLv2ProfessionZyk = `${lrmv2_api_prefix}/importLv2ProfessionZyk`;
// 检查并存储分库日志表标识
apiurls.saveLv2LogFlag = `${lrmv2_api_prefix}/saveLv2LogFlag`;
// 三级两平台 -初始化日志表
apiurls.initializeLogTable = `${lrmv2_api_prefix}/initializeLogTable`;
// 校园端 -根据资源库Id获取校园列表
apiurls.getSchoolListByZykId = `${lrmv2_api_prefix}/getSchoolListByZykId`;
// 关联专业信息
apiurls.associationMajorInfo = `${lrmv2_api_prefix}/associationMajorInfo`;

// 无活动报告相关接口
//1.1 建设院校师生实名情况
apiurls.getSchoolBuildRealNameStudentTeacher = `${lrmv2_api_prefix}/getSchoolBuildRealNameStudentTeacher`;
//1.2 近三年建设院校学生新增与实名情况
apiurls.getPastThreeYearBuildSchoolStudentCase = `${lrmv2_api_prefix}/getPastThreeYearBuildSchoolStudentCase`;
//2.1 建设院校实名师生无活动情况 -无活动总体情况
apiurls.getNoActivityOverallCase = `${lrmv2_api_prefix}/getNoActivityOverallCase`;
//2.2 近三年无活动情况
apiurls.getPastThreeYearNoActivityCase = `${lrmv2_api_prefix}/getPastThreeYearNoActivityCase`;
//2.3 近三年建设院校新增实名学生无活动情况
apiurls.getPastThreeYearBuildSchoolAddRealNameStudentCase = `${lrmv2_api_prefix}/getPastThreeYearBuildSchoolAddRealNameStudentCase`;
//3. 素材资源无访问情况
apiurls.getResourceNoVisitCase = `${lrmv2_api_prefix}/getResourceNoVisitCase`;
//4. 课程无访问情况
apiurls.getNoVisitCourseCase = `${lrmv2_api_prefix}/getNoVisitCourseCase`;

// 平台推送原始数据详情查看
apiurls.getOriginalDataInfo = `${lrmv2_api_prefix}/pushDataManage/getOriginalDataInfo`;

// 校园端 -更新数据
apiurls.initDeclBuildBaseInfo = `${lrmv2_api_prefix}/initDeclBuildBaseInfo`;
// 校园端 -获取建设基础表信息
apiurls.getDeclBuildBaseInfo = `${lrmv2_api_prefix}/getDeclBuildBaseInfo`;
// 校园端 -获取上个月建设基础表信息
apiurls.getContrastDeclBuildBaseInfo = `${lrmv2_api_prefix}/getContrastDeclBuildBaseInfo`;
// 校园端 -建设基础表信息保存
apiurls.saveDeclBuildBaseInfo = `${lrmv2_api_prefix}/saveDeclBuildBaseInfo`;
// 校园端 -导出基础建设表信息
apiurls.exportBuildBaseInfo = `${lrmv2_api_prefix}/exportBuildBaseInfo`;

// 校园端 -建设基础表信息保存
apiurls.getCountryJointChargeUnitDetail = `${appcelapi_api_prefix}/provinceReport/getCountryJointChargeUnitDetail`;

apiurls.getProvinceReportZykOverview = `${appcelapi_api_prefix}/provinceReport/getProvinceReportZykOverview`;

// 新版省份整体报告相关接口
// 1. 省份数据总览
apiurls.getProvinceOverview = `${appcelapi_api_prefix}/provinceWholeMonthReport/getProvinceOverview`;
// 1.1.1 用户建设及应用情况
apiurls.getProvinceUserData = `${appcelapi_api_prefix}/provinceWholeMonthReport/getProvinceUserData`;
// 1.1.2 课程建设及应用情况
apiurls.newGetCourseBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCourseBuildApplyCase`;
// 1.1.3 资源建设及应用情况
apiurls.newGetResourceBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getResourceBuildApplyCase`;
// 1.2.1 院校师生参与情况
apiurls.getSchoolTeacherStudentJoinCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getSchoolTeacherStudentJoinCase`;
// 1.2.2 院校课程建设及应用情况
apiurls.getSchoolBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getSchoolBuildApplyCase`;
// 1.2.3 院校资源建设及应用情况
apiurls.getSchoolBuildResourceApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getSchoolBuildResourceApplyCase`;
// 1.3.1 用户应用情况
apiurls.getUserApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getUserApplyCase`;
// 1.3.2 课程运行情况
apiurls.getCourseRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCourseRunCase`;
// 1.3.3 资源运行情况
apiurls.getResourceRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getResourceRunCase`;

// 2.1 国家级专业教学资源库运行分析
apiurls.getCountryZykRunAnalysis = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCountryZykRunAnalysis`;
// 2.1.1 用户建设及应用情况
apiurls.newGetCountryUserBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCountryUserBuildApplyCase`;
// 2.1.2 课程建设及应用情况
apiurls.newGetCountryCourseBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCountryCourseBuildApplyCase`;
// 2.1.3 资源建设应用情况
apiurls.newGetCountryResourceBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCountryResourceBuildApplyCase`;
// 2.1.4 资源库总体应用情况
apiurls.getZykWholeApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykWholeApplyCase`;
// 2.1.5 资源库建设与应用情况排名
apiurls.getCountryZykBuildRank = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCountryZykBuildRank`;
// 2.1.6.1.1 用户新增情况
apiurls.getUserAddCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getUserAddCase`;
// 2.1.6.1.2 用户活跃情况
apiurls.getUserActiveCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getUserActiveCase`;
// 2.1.6.1.3. 用户访问情况排名
apiurls.newGetUserVisitRank = `${appcelapi_api_prefix}/provinceWholeMonthReport/getUserVisitRank`;
// 2.1.6.2 资源库课程运行情况资源库课程运行情况
apiurls.getZykCourseRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykCourseRunCase`;
// 2.1.6.3 资源库资源运行情况
apiurls.getZykResourceRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykResourceRunCase`;
// 2.2 国家及省市校级专业教学资源库运行分析
apiurls.getZykRunAnalysis = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykRunAnalysis`;
// 2.2.1 用户建设及应用情况
apiurls.newGetUserBuildApplyCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getUserBuildApplyCase`;
// 2.2.2 课程建设及应用情况
apiurls.getCourseBuildCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getCourseBuildCase`;
// 2.2.3 资源建设及应用情况
apiurls.getZykResourceBuildCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykResourceBuildCase`;
// 2.2.4 资源库总体应用情况
apiurls.getZykWholeCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getZykWholeCase`;
// 2.2.5.1.1 用户新增情况
apiurls.getThreeLevelUserAdd = `${appcelapi_api_prefix}/provinceWholeMonthReport/getThreeLevelUserAdd`;
// 2.2.5.1.2 用户活跃情况
apiurls.getThreeLevelUserActive = `${appcelapi_api_prefix}/provinceWholeMonthReport/getThreeLevelUserActive`;
// 2.2.5.1.3 用户访问情况排名
apiurls.getThreeLevelUserVisitRank = `${appcelapi_api_prefix}/provinceWholeMonthReport/getThreeLevelUserVisitRank`;
// 2.2.5.2 资源库课程运行情况
apiurls.getThreeLevelZykCourseRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getThreeLevelZykCourseRunCase`;
// 2.2.5.3 资源库资源运行情况
apiurls.getThreeLevelZykResourceRunCase = `${appcelapi_api_prefix}/provinceWholeMonthReport/getThreeLevelZykResourceRunCase`;

// 3. 在线精品课应用分析
apiurls.getProvinceOnlineCourseAnalysis = `${appcelapi_api_prefix}/provinceWholeMonthReport/getProvinceOnlineCourseAnalysis`;
// 3.1 在线精品课建设情况
apiurls.getOnlineCourseBuild = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOnlineCourseBuild`;
// 3.2 在线精品课访问情况
apiurls.getOnlineCourseVisit = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOnlineCourseVisit`;
// 3.3 在线精品课开课情况
apiurls.getOnlineBeginCourse = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOnlineBeginCourse`;
// 3.4 在线精品课程选课情况
apiurls.getOnlineCourseSelection = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOnlineCourseSelection`;
// 4. 视频公开课应用分析
apiurls.getProvinceOpenCourseAnalysis = `${appcelapi_api_prefix}/provinceWholeMonthReport/getProvinceOpenCourseAnalysis`;
// 4.1 视频公开课建设情况
apiurls.getOpenCourseBuild = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOpenCourseBuild`;
// 4.2 视频公开课访问情况
apiurls.getOpenCourseVisit = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOpenCourseVisit`;
// 4.3 视频公开课选课情况
apiurls.getOpenCourseSelection = `${appcelapi_api_prefix}/provinceWholeMonthReport/getOpenCourseSelection`;

// 平台信息管理
// 查询平台表信息
apiurls.getPlatformInfo = lrmv2apilv2Platform + "/getPlatformInfo";
// 新增或修改平台管理表信息
apiurls.addPlatformInfo = lrmv2apilv2Platform + "/addPlatformInfo";
// 删除平台管理表信息
apiurls.delPlatformInfo = lrmv2apilv2Platform + "/delPlatformInfo";

// 校园端 - 指标统计管理相关接口
// 查询指标统计管理表信息
apiurls.getStatManageInfo = lrmv2_api_prefix + `/stat/getStatManageInfo`;
// 新增指标统计管理表信息
apiurls.addStatManageInfo = lrmv2_api_prefix + `/stat/addStatManageInfo`;
// 删除指标统计管理表信息
apiurls.delStatManageInfo = lrmv2_api_prefix + `/stat/delStatManageInfo`;
// 修改指标统计管理表信息
apiurls.editStatManageInfo = lrmv2_api_prefix + `/stat/editStatManageInfo`;
// 执行指标统计
apiurls.executeStat = lrmv2_api_prefix + `/stat/executeStat`;

// 建设基础数据管理 -查询功能
apiurls.getBuildBaseManageInfo = lrmv2_api_prefix + `/getBuildBaseManageInfo`;
// 建设基础数据管理 -导出功能
apiurls.exportBuildBaseManageInfo = lrmv2_api_prefix + `/exportBuildBaseManageInfo`;
// 建设基础数据管理 -更新功能
apiurls.initializeBuildBaseManageInfo = lrmv2_api_prefix + `/initializeBuildBaseManageInfo`;
// 存储三级两平台信息到建设基础数据管理表
apiurls.saveLv2ToManageInfo = lrmv2_api_prefix + `/saveLv2ToManageInfo`;

// 定时任务管理 -查询
apiurls.getScheduleManageInfo = lrmv2_api_prefix + `/getScheduleManageInfo`;
// 定时任务管理 -新增-修改
apiurls.addScheduleTask = lrmv2_api_prefix + `/addScheduleTask`;
// 定时任务管理 -删除
apiurls.delScheduleTask = lrmv2_api_prefix + `/delScheduleTask`;
// 定时任务管理 -执行
apiurls.executeTask = lrmv2_api_prefix + `/executeTask`;

// 常用工具 -下发详情
apiurls.getDistributeDetails = lrmv2_api_prefix + `/getDistributeDetails`;
// 常用工具 -下发详情导出
apiurls.exportDistributeDetails = lrmv2_api_prefix + `/exportDistributeDetails`;

// 推送记录管理查询
apiurls.getZykUpDataMonitorDb = lrm_api_prefix + `/monitorDb/getZykUpDataMonitorDb`;
// 处理
apiurls.zykProcessData = lrm_api_prefix + `/monitorDb/processData`;
// 重置为未处理
apiurls.resetUnProcess = lrm_api_prefix + `/monitorDb/resetUnProcess`;

// 报告导出管理表 -查询
apiurls.getLv2ReportExportInfo = lrm_api_prefix + `/getLv2ReportExportInfo`;
// 报告导出管理表 -新增-修改
apiurls.addLv2ReportExportInfo = lrm_api_prefix + `/addLv2ReportExportInfo`;
// 报告导出管理表 -删除
apiurls.delLv2ReportExportInfo = lrm_api_prefix + `/delLv2ReportExportInfo`;
// 报告导出管理表 -导出
apiurls.exportLv2ReportExportInfo = lrm_api_prefix + `/exportLv2ReportExportInfo`;

// 校园端-月报相关接口
// 报告标题信息
apiurls.getReportTitleInfo = `${lrmv2_api_prefix}/monthReport/getReportTitleInfo`;
// 1.1 当前资源库用户情况
apiurls.getZykCurrentUserSituation = `${lrmv2_api_prefix}/monthReport/getZykCurrentUserSituation`;
// 1.2 当前资源库建设单位注册用户情况
apiurls.getBuildUnitRegisterUserSituation = `${lrmv2_api_prefix}/monthReport/getBuildUnitRegisterUserSituation`;
// 1.3 当前资源库资源建设情况
apiurls.getZykCurrentResourceBuild = `${lrmv2_api_prefix}/monthReport/getZykCurrentResourceBuild`;
// 1.4 当前资源库标准化课程建设及应用情况
apiurls.getZykCurrentStandCourseSituation = `${lrmv2_api_prefix}/monthReport/getZykCurrentStandCourseSituation`;
// 1.5 当前资源库用户行为类型分布
apiurls.getUserBehaviorDistribution = `${lrmv2_api_prefix}/monthReport/getUserBehaviorDistribution`;
// 1.6 资源库无活动数据情况
apiurls.getZykNoActivitySituation = `${lrmv2_api_prefix}/monthReport/getZykNoActivitySituation`;
// 2.1 本月用户新增情况 图7 本月用户新增情况
apiurls.getMonthUserAddSituation = `${lrmv2_api_prefix}/monthReport/getMonthUserAddSituation`;
// 2.1 本月用户新增情况 图8 主持单位与参建单位新增用户占比情况
apiurls.getChargeAndBuildUnitUserAdd = `${lrmv2_api_prefix}/monthReport/getChargeAndBuildUnitUserAdd`;
// 2.2 本月素材资源新增情况
apiurls.getMonthResourceAddSituation = `${lrmv2_api_prefix}/monthReport/getMonthResourceAddSituation`;
// 2.3 本月课程新增情况
apiurls.getMonthCourseAddSituation = `${lrmv2_api_prefix}/monthReport/getMonthCourseAddSituation`;
// 3.1 本月资源访问情况
apiurls.getMonthResourceAccessSituation = `${lrmv2_api_prefix}/monthReport/getMonthResourceAccessSituation`;
// 3.2 本月课程访问情况
apiurls.getMonthCourseAccessSituation = `${lrmv2_api_prefix}/monthReport/getMonthCourseAccessSituation`;
// 3.3 本月用户访问情况 图13 本月用户访问情况
apiurls.getMonthUserAccessSituation = `${lrmv2_api_prefix}/monthReport/getMonthUserAccessSituation`;
// 3.3 本月用户访问情况 图14 本月用户访问时间段分布
apiurls.getMonthUserAccessDistribution = `${lrmv2_api_prefix}/monthReport/getMonthUserAccessDistribution`;
// 3.4 本月访问量较多的教师
apiurls.getMonthAccessMoreTeacherRank = `${lrmv2_api_prefix}/monthReport/getMonthAccessMoreTeacherRank`;
// 3.5 本月访问量较多的学生
apiurls.getMonthAccessMoreStudentRank = `${lrmv2_api_prefix}/monthReport/getMonthAccessMoreStudentRank`;

export default apiurls;
