// 单位换算
exports.install = function (Vue, options) {
  Vue.prototype.unitMethod = function (num, unit) {
    if (!num) {
      num = 0;
    }
    num = Number(num);
    if (Math.abs(num) > 1000000000000) {
      num = Math.floor((num / 1000000000000).toFixed(2));
      unit = `万亿${unit}`;
    } else if (Math.abs(num) > 100000000000) {
      num = Math.floor((num / 100000000000).toFixed(2));
      unit = `千亿${unit}`;
    } else if (Math.abs(num) > 100000000) {
      num = Math.floor((num / 100000000).toFixed(2));
      unit = `亿${unit}`;
    } else if (Math.abs(num) > 10000) {
      num = Math.floor((num / 10000).toFixed(2));
      unit = `万${unit}`;
    }
    //   else if (Math.abs(num) > 1000) {
    //      num = Math.floor((num / 1000).toFixed(1))
    //      unit = '千' + unit
    //  }
    else {
      num = Math.floor(num);
      unit = unit;
    }
    return [num, unit];
  };
};
