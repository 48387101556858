<template>
  <div id="app" style="height: 100%">
    <game-Header v-if="this.$route.meta.HeaderNavShow"></game-Header>
    <router-view />
    <game-Footer v-if="this.$route.meta.HeaderNavShow"></game-Footer>
  </div>
</template>

<script>
import gameHeader from "@/views/game/gameHeader.vue"; // 头部
import gameFooter from "@/views/game/gameFooter.vue"; // 脚部
export default {
  components: {
    gameHeader,
    gameFooter
  },
  data() {
    return {

    };
  },
  created(){
    let percent =this.detectZoom()
    if(percent==150){
      document.body.style.zoom="65%";
    }else if(percent==125){
      document.body.style.zoom="80%";
    }else if(percent==175){
      document.body.style.zoom="58%";
    }
  },
  methods: {
    detectZoom() {
      let ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
      ) {
        ratio = window.outerWidth / window.innerWidth;
      }
      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      console.log("缩放比例", ratio);
      return ratio;
    },
  },
};
</script>

<style lang="scss">
body {
  padding: 0px;
  margin: 0px;
}

/*全局滚动条样式*/
::-webkit-scrollbar {
  width: 0px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  /* background-color: #01c8dc;
border-radius: 3px; */
  background-color: #b6b6b6;
}

/*提示弹出框样式*/
.el-message {
  background: #161d37 !important;
  border-color: #161d37 !important;
  font-size: 18px;
}

.el-message .el-message__content {
  font-size: 15px;
}

// 删除或退出提示
.el-message-box {
  min-width: 350px !important;
  max-width: 800px !important;
  width: auto !important;
  /*background: #202740 !important;*/
  /*border-color: #202740 !important;*/
}


.el-message-box .el-message-box__title {
  color: black;
  font-size: 15px;
  display: flex;
  align-items: center;
}
/*.el-message-box .el-message-box__title::before {*/
/*  content: "";*/
/*  background: url(./assets/img/icon/headerIcon.svg) 0px 0px no-repeat;*/
/*  background-size: 100% 100%;*/
/*  width: 18px;*/
/*  height: 18px;*/
/*  display: inline-block;*/
/*  margin: 0px 10px;*/
/*}*/

.el-message-box .el-message-box__headerbtn {
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: 700;
}
.el-message-box .el-message-box__close.el-icon-close {
  color: #ef036b;
}

// 中间内容
.el-message-box .el-message-box__content {
  min-height: 50px;
  max-height: 400px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  color: #5a6699;
  font-size: 15px;
}

// 按钮
.el-message-box .el-message-box__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.el-message-box .el-message-box__btns .el-button {
  /*background: #464d66;*/
  /*border-color: transparent;*/
  /*color: #fff;*/
}

.el-message-box .el-message-box__btns .el-button:hover {
  background: #22d8de;
}

// 调换一下确定和取消位置
.el-message-box .el-message-box__btns .el-button:last-child {
  order: -1;
  margin-right: 10px;
  margin-left: -10px;
}

.v-modal {
  opacity: 0.8 !important;
}
</style>
