import axios from "axios";
import router from "../router/index";
import {
  message
} from "./resetMessage"; // 引入重写element的提示弹窗

const service = axios.create({
  baseURL: "",
  timeout: 5000 * 12 * 3,
});

// 请求前附令牌
service.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token")
    config.headers = {
      ...config.headers,
      token: token, // 附令牌到请求头
    };
    // config.headers.lrmv2_token = sessionStorage.getItem("lrmv2_token"); // 附令牌到请求头
    // if (config.formData) {
    //   config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
    //   console.log("sddsasddas", config);
    // }
    if (!config.formData) {
      config.data = {
        data: config.data || {},
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    if(response.data.code===401){
      sessionStorage.setItem("userName", "")
    }
    return response.data;
  },
  (error) => {
    console.log(error)
    message.error("请求失败");
    return Promise.reject({
      code: 1030,
      message: "请求失败！"
    });
  }
);

export default service;
