import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: { // 全局数据缓存
    isVague: false, // 是否需要全局模糊
    cloudList: [], // 缓存词云图，防止一拖动浏览器窗口导致颜色变化
    isScreen: false, // 是否启用全屏了
    monitoringCloudColor: false, // 监测云图切换皮肤
    // menuList:[],              //菜单
    loading: false, // 时候显示加载
    statusRadio: sessionStorage.getItem("statusRadio") || "1",
  },
  getters: { // 监听state中的数据变化(最新状态)
    isShow(state) {
      return state;
    },
    // isCloudList(state) {
    //     return state.cloudList
    // },
  },
  mutations: {
    modifyVague(state, istrue) {
      state.isVague = istrue;
    },
    modifyClundList(state, data) {
      state.cloudList.push(data);
    },
    modifyScreen(state, data) {
      state.isScreen = data;
    },
    monitoringClouds(state, istrue) {
      state.monitoringCloudColor = istrue;
    },
    // menuevent(state, data){
    //     state.menuList = data
    // }
    isLoading(state, istrue) {
      state.loading = istrue;
    },
    updateStatusRadio(state, newVal) {
      state.statusRadio = newVal;
      sessionStorage.setItem("statusRadio", newVal);
    },
  },
  actions: {},
  modules: {},
});
