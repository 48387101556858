<template>
  <div class="loading">
    <i class="el-icon-loading"></i>
    <p class="el-loading-text">拼命加载中...</p>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0px;
  margin: 0px;
  margin-top: 5px;
}
.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
</style>
