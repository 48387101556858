// 通用接口前缀
const common_api_prefix = "/renren-admin";
const gameApiurls = {};
// 游戏信息
gameApiurls.gameList = `${common_api_prefix}/front/page`;

gameApiurls.gameInfo = `${common_api_prefix}/front/getGameInfo`;

gameApiurls.saveNeedGame = `${common_api_prefix}/front/saveNeedGame`;

gameApiurls.regiterUser = `${common_api_prefix}/regiterUser`;

gameApiurls.frontLogin = `${common_api_prefix}/frontLogin`;

gameApiurls.adminLogin = `${common_api_prefix}/adminLogin`;

gameApiurls.getUserVipInfo = `${common_api_prefix}/admin/back/getUserVipInfo`;

gameApiurls.getGameDownLoadInfo = `${common_api_prefix}/admin/back/getGameDownLoadInfo`;

gameApiurls.adminGameList = `${common_api_prefix}/admin/back/page`;

gameApiurls.checkAdminByToken = `${common_api_prefix}/checkAdminByToken`;

gameApiurls.getRollPictures = `${common_api_prefix}/front/getRollPictures`;

gameApiurls.openVIP = `${common_api_prefix}/admin/back/openVIP`;

gameApiurls.getLoginUserInfo = `${common_api_prefix}/sys/user/info`;

gameApiurls.uploadFile = `${common_api_prefix}/sys/oss/upload`;

gameApiurls.updateHeaderUrl = `${common_api_prefix}/sys/user/updateHeaderUrl`;

gameApiurls.jnhEnablePage = `${common_api_prefix}/jnh/enablePage`;

gameApiurls.queryEnablePage = `${common_api_prefix}/jnh/queryEnablePage`;

gameApiurls.getNoLimitDownLoadList = `${common_api_prefix}/admin/back/getNoLimitDownLoadList`;

gameApiurls.getNoLimitDownLoadUrlByCode = `${common_api_prefix}/admin/back/getNoLimitDownLoadUrlByCode`;


export default gameApiurls;
