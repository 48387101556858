import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// fix重复push replace同一个location错误
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 获取原型对象上的replace函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "游戏首页",
    component: () => import("@/views/game/gameIndex.vue"),
    meta:{
      HeaderNavShow:true,
      title: 'JGame - 找寻最初的快乐'
    }
  },
  {
    path: "/gameDetail",
    name: "游戏详情",
    component: () => import("@/views/game/gameDetail.vue"),
    meta:{
      HeaderNavShow:true,
      title: 'JGame - 找寻最初的快乐'
    }
  },
  {
    path: "/gameSearch",
    name: "游戏查询列表",
    component: () => import("@/views/game/gameSearch.vue"),
    meta:{
      HeaderNavShow:true,
      title: 'JGame - 找寻最初的快乐'
    }
  },
  {
    path: "/gameToolsPage",
    name: "游戏工具列表",
    component: () => import("@/views/game/gameToolsPage.vue"),
    meta:{
      HeaderNavShow:true,
      title: 'JGame - 找寻最初的快乐'
    }
  },
  {
    path: "/jnh_ww_127",
    name: "嘉年华-薇薇页面",
    component: () => import("@/views/jnh/jnh_ww_127.vue"),
    meta:{
      HeaderNavShow:false,
      title: '专属于薇薇的嘉年华'
    }
  },
  {
    path: "/jnh_ww_128",
    name: "嘉年华-文文页面",
    component: () => import("@/views/jnh/jnh_ww_128.vue"),
    meta:{
      HeaderNavShow:false,
      title: '专属于嘉文的嘉年华'
    }
  },
  {
    path: "/enablePage",
    name: "开启嘉年华",
    component: () => import("@/views/jnh/enablePage.vue"),
    meta:{
      HeaderNavShow:false,
      title: '开启嘉年华'
    }
  },
  {
    path: "/mobileIndex",
    name: "手机端 - 首页",
    component: () => import("@/views/game_mobile/index.vue"),
    meta:{
      HeaderNavShow:false,
      title: 'JGame - 找寻最初的快乐'
    }
  },
  {
    path: "/mobileDetail",
    name: "手机端 - 详情页",
    component: () => import("@/views/game_mobile/mobileDetail.vue"),
    meta:{
      HeaderNavShow:false,
      title: 'JGame - 找寻最初的快乐'
    }
  }

];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router;
