<template>
  <el-container>
    <el-footer>
      <div class="site-footer" >
        <p class="site-info">
          © 游戏每周定时持续更新 - 本站资源全部来源于网络，如有侵犯您的权益请致邮garwenllo@163.com
        </p>
      </div>
    </el-footer>
  </el-container>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  // 卸载
  beforeDestroy() {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
.site-footer{
  border-top: 1px solid #ccc;
  background-color: #ffffff;
  color: #aaa;
  padding: 20px 0;
  text-align: center;
}
.site-info{
  margin: 15px auto 0;
  max-width: 750px;
  font-size: 13px;
}
</style>
