import Vue from "vue";
// import ElementUI from "element-ui";
import loading from "@/components/loading.vue"; // 表格加载组件
import unit from "@/utils/unit"; // 单位换算
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "./utils/request";
import apiurls from "./constants/apiurls";
import gameApiurls from "./constants/gameApiurls";
import {
  message
} from "./utils/resetMessage"; // 引入重写element的提示弹窗
import "./assets/css/icon.css";
import "./assets/css/custom.scss";
import "./assets/css/popUp.scss";
import "./assets/css/tableList.scss";
// import "./assets/css/public_report.scss";
import jsonView from "vue-json-views";
import Utils from "./utils/utils";

Vue.prototype.$Utils = Utils;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$apiurls = apiurls;
Vue.prototype.$gameApiurls = gameApiurls;
Vue.prototype.$message = message; // 重写message提示框，让提示框不重复提示,使用必须放置在ElementUI使用后
Vue.use(unit);
Vue.component("loading", loading);
Vue.component("jsonView",jsonView)

router.beforeEach((to, from, next) => {
  if (to.matched.some((res) => res.meta.isLogin)) {
    // 判断是否需要登录
    if (to.query.token) {
      const token = to.query.token;
      sessionStorage.setItem("token", token);
    }
    if (sessionStorage.getItem("token")) {
      next();
    } else {
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
