/**
 * Created by lijingwei on 2018/1/12.
 */
import CryptoJS from 'crypto-js';

// SecureUtil = function () {

const CONTRAST = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DICTIONARIES = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890!@#$%^&*()';
const IV_TEXT = '0102030405060708';

/**
 * 字符串转byte[]
 */
export function stringToByte(str) {
  const bytes = new Array();
  let len; let
    c;
  len = str.length;
  for (let i = 0; i < len; i++) {
    c = str.charCodeAt(i);
    if (c >= 0x010000 && c <= 0x10FFFF) {
      bytes.push(((c >> 18) & 0x07) | 0xF0);
      bytes.push(((c >> 12) & 0x3F) | 0x80);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000800 && c <= 0x00FFFF) {
      bytes.push(((c >> 12) & 0x0F) | 0xE0);
      bytes.push(((c >> 6) & 0x3F) | 0x80);
      bytes.push((c & 0x3F) | 0x80);
    } else if (c >= 0x000080 && c <= 0x0007FF) {
      bytes.push(((c >> 6) & 0x1F) | 0xC0);
      bytes.push((c & 0x3F) | 0x80);
    } else {
      bytes.push(c & 0xFF);
    }
  }
  return bytes;
}

/**
 * byte[]转字符串
 */
function byteToString(arr) {
  if (typeof arr === 'string') {
    return arr;
  }
  let str = '';
  const _arr = arr;
  for (let i = 0; i < _arr.length; i++) {
    const one = _arr[i].toString(2);
    const v = one.match(/^1+?(?=0)/);
    if (v && one.length == 8) {
      const bytesLength = v[0].length;
      let store = _arr[i].toString(2).slice(7 - bytesLength);
      for (let st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2);
      }
      str += String.fromCharCode(parseInt(store, 2));
      i += bytesLength - 1;
    } else {
      str += String.fromCharCode(_arr[i]);
    }
  }
  return str;
}

/**
 * 十六进制字符串转字节数组
 */
function Str2Bytes(str) {
  let pos = 0;
  let len = str.length;
  if (len % 2 != 0) {
    return null;
  }
  len /= 2;
  const hexA = new Array();
  for (let i = 0; i < len; i++) {
    const s = str.substr(pos, 2);
    const v = parseInt(s, 16);
    hexA.push(v);
    pos += 2;
  }
  return hexA;
}

/**
 * 字节数组转十六进制字符串
 */
function Bytes2Str(arr) {
  let str = '';
  for (let i = 0; i < arr.length; i++) {
    let tmp = arr[i].toString(16);
    if (tmp.length == 1) {
      tmp = `0${tmp}`;
    }
    str += tmp;
  }
  return str;
}

/**
 * 字符串插入指定位置字符
 */
function insertChar(str, char, pos) {
  const oldstrArray = [str.substring(0, pos), str.substring(pos, str.length)];
  //        console.log("拆分后字符串 = %s", oldstrArray.toString());
  const newstr = oldstrArray[0] + char + oldstrArray[1];
  //        console.log("组合后字符串 = %s", newstr);
  return newstr;
}

/**
 * 加密
 */
export function encryption(content) {
  content = content.replace(/,/g, 'FENGEFU');
  //        console.log("密前文本 = %s", content);
  // 根据DICTIONARIES生成16为动态密钥
  const maxPos = DICTIONARIES.length; // 密钥字典最大长度
  const LEN = 16; // 密钥长度
  let password = ''; // 动态密钥
  const array = new Array(maxPos)
    .fill(0)
    .map((v, i) => i + 1)
    .sort(() => 0.5 - Math.random())
    .filter((v, i) => i < LEN); // 生成16个不同随机数,作为字典抽取角标
  for (var i = 0; i < array.length; i++) {
    password += DICTIONARIES.charAt(array[i] - 1);
  }
  // console.log("原始动态密钥 = %s", password);
  // AES加密内容
  const key = CryptoJS.enc.Utf8.parse(password);
  const iv = CryptoJS.enc.Utf8.parse(IV_TEXT);
  const encryptResult = CryptoJS.AES.encrypt(content, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
    // 转BASE64,增加加密强度
  const encryptedStr = encryptResult.ciphertext.toString();
  // // encryptedStr = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedStr));
  // encryptedStr = Bytes2Str(stringToByte(encryptedStr));
  // console.log(encryptedStr);
  // console.log("原始加密后密文 = %s", encryptedStr);
  // 密钥干扰
  let currentTimeMillis = (new Date()).valueOf().toString(); // 获得当前时间戳
  const currentTimeMillisLength = currentTimeMillis.length;
  if (currentTimeMillisLength < 13) { // 解决特殊机型获取当前时间毫秒位数异常
    const missingCount = 13 - currentTimeMillisLength;
    for (var i = 0; i < missingCount; i++) {
      currentTimeMillis += i;
    }
  } else if (currentTimeMillis.length > 13) {
    currentTimeMillis = currentTimeMillis.substring(0, 13);
  }
  //        console.log("当前时间戳 = %s", currentTimeMillis);
  //        console.log("当前时间戳长度 = %s", currentTimeMillis.length);
  const h = new Array(); // 实际插入角标集合
  const arrayTime = new Array(16)
    .fill(0)
    .map((v, i) => i + 1)
    .sort(() => 0.5 - Math.random())
    .filter((v, i) => i < currentTimeMillis.length); // 生成16个不同随机数,作为字典抽取角标
  for (var i = 0; i < arrayTime.length; i++) {
    //            console.log("取16位随机数第 %s 位", arrayTime[i] - 1);
    h[i] = arrayTime[i] - 1;
  }
  //        console.log("集合长度 = %s", h.length);
  let indexs = ''; // 插入角标字符串,每位是要插入的角标
  // 随机选取要插入干扰字符
  const arrayMix = new Array(13)
    .fill(0)
    .map((v, i) => i + 1)
    .sort(() => 0.5 - Math.random())
    .filter((v, i) => i < h.length); // 生成13个不同随机数,作为字典抽取角标
    //        console.log("arrayMix集合长度 = %s", arrayMix.length);
  for (var i = 0; i < arrayMix.length; i++) {
    const charAt = currentTimeMillis.charAt(arrayMix[i] - 1); // 取随机干扰字符
    //            console.log("干扰字符 = %s", charAt);
    password = insertChar(password, charAt, h[i]);
    //            console.log("已插入干扰字符密钥 = %s", password);
    indexs += CONTRAST.charAt(h[i]); // 将10进制角标映射为16进制标识方式
  }
  // console.log("混淆插入角标索引集合字符串 = %s", indexs);
  // console.log("混淆后的动态密钥 = %s", password);
  // 将角标分成两节(0~6)(6~13)
  const qian = indexs.substring(0, 6);
  const hou = indexs.substring(6, 13);
  // 将分好的两节分别拼接到角标前后
  password = qian + password + hou;
  //        console.log("最终携带混淆角标混淆后的动态密钥 = %s", password);
  const result = `${password},${encryptedStr}`;
  // console.log("最终加密文本 = %s", result);
  const byteResult = stringToByte(result);
  //        console.log("最终字节加密文本 = %s", byteResult);
  const hexResult = Bytes2Str(byteResult).toUpperCase();
  // console.log("最终16进制加密文本 = %s", hexResult);
  // console.log("--------------------------");
  return hexResult;
}

/**
 * 解密
 * @param content
 */
export function decryption(content) {
  const btyeMessage = Str2Bytes(content);
  //        console.log("最终16进制转字符串加密文本Byte = %s", btyeMessage);
  const message = byteToString(btyeMessage);
  // console.log("字节转字符串加密文本 = %s", message);
  const resultArray = message.split(',');
  //        console.log("混淆密钥 = %s", resultArray[0]);
  const realMessage = resultArray[1];
  // console.log("实际密文 = %s", realMessage);
  const qian = resultArray[0].substring(0, 6);
  const hou = resultArray[0].substring(resultArray[0].length - 7, resultArray[0].length);
  const indexs16 = qian + hou;
  let password = resultArray[0].substring(6, resultArray[0].length - 7);
  // console.log("干扰字符角标 = %s", indexs16);
  // console.log("干扰后密钥 = %s", password);
  const indexArray = indexs16.split('');
  //        console.log("角标数量 = %s", indexArray);
  for (let i = indexArray.length - 1; i >= 0; i--) {
    const pos = parseInt(indexArray[i], 16); // 16进制字符转10进制字符
    //            console.log("干扰字符角标 = %s", pos);
    password = password.substring(0, pos) + password.substring(pos + 1);
    //            console.log("删除干扰字符后 = %s", password);
  }
  // console.log("实际密钥 = %s", password);
  // AES解密内容
  const key = CryptoJS.enc.Utf8.parse(password);
  const iv = CryptoJS.enc.Utf8.parse(IV_TEXT);
  // realMessage = CryptoJS.enc.Hex.parse(realMessage).toString();
  const encryptedHexStr = CryptoJS.enc.Hex.parse(realMessage);
  const encryptedBase64Str2 = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str2, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8);
  decryptedStr = decryptedStr.replace(/FENGEFU/g, ',');
  // console.log("最终文本 = %s", decryptedStr);
  return decryptedStr;
}
// };

export default {
  encryption,
  decryption,
};
